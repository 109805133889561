import React from 'react';
import { Input, InputGroup, InputLeftElement, InputRightElement, Textarea, FormControl, FormLabel, VStack } from '@chakra-ui/react';
import { PhoneInput } from './PhoneInput';
import { FormattedFormErrorMessage } from './FormattedErrorMessage';

export const InputComponent = ({ label, placeholder, name, type="text", value, isInvalid, error, onChange, inputLeftElement=null, inputRightElement=null, inputRightButton=null, isDisabled=false, onPaste=null, max=null, boxProps={} }) => {
    if (type == "textarea"){
        return (
            <VStack {...boxProps}>
                <FormControl id={name} isInvalid={isInvalid}>
                    <FormLabel>{label}</FormLabel>
                    <Textarea placeholder={placeholder} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled} onPaste={onPaste} />
                    <FormattedFormErrorMessage error={error} />
                </FormControl>
            </VStack>
        )
    } else if (type == "phonenumber"){ 
        return (
            <VStack {...boxProps}>
                <FormControl id={name} isInvalid={isInvalid}>
                    <FormLabel>{label}</FormLabel>
                    <PhoneInput 
                        placeholder={placeholder} 
                        value={value} 
                        onChange={onChange} 
                        onPaste={onPaste} 
                        isInvalid={isInvalid} 
                        isDisabled={isDisabled} />
                    <FormattedFormErrorMessage error={error} />
                </FormControl>
            </VStack>
        )
    } else {
        if (inputRightElement && inputLeftElement){
            return (
                <VStack {...boxProps}>
                    <FormControl id={name} isInvalid={isInvalid}>
                        <FormLabel>{label}</FormLabel>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                color='gray.300'
                            >
                                {inputLeftElement}
                            </InputLeftElement>
                            <Input placeholder={placeholder}  max={max} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled}  onPaste={onPaste}  />

                            <InputRightElement
                                pointerEvents='none'
                                color='gray.300'
                            >
                                {inputRightElement}
                            </InputRightElement>
                        </InputGroup>
                        <FormattedFormErrorMessage error={error} />
                    </FormControl>
                </VStack>
            )
        } else if (inputRightElement){  
            return (
                <VStack {...boxProps}>
                    <FormControl id={name} isInvalid={isInvalid}>
                        <FormLabel>{label}</FormLabel>
                        <InputGroup>
                            <Input placeholder={placeholder}  max={max} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled}  onPaste={onPaste} />

                            <InputRightElement
                                pointerEvents='none'
                                color='gray.300'
                            >
                                {inputRightElement}
                            </InputRightElement>
                        </InputGroup>
                        <FormattedFormErrorMessage error={error} />
                    </FormControl>
                </VStack>
            )

        } else if (inputRightButton){  
            return (
                <VStack {...boxProps}>
                    <FormControl id={name} isInvalid={isInvalid}>
                        <FormLabel>{label}</FormLabel>
                        <InputGroup>
                            <Input placeholder={placeholder}  max={max} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled}  onPaste={onPaste} />

                            <InputRightElement>
                                {inputRightButton}
                            </InputRightElement>
                        </InputGroup>
                        <FormattedFormErrorMessage error={error} />
                    </FormControl>
                </VStack>
            )

        } else if (inputLeftElement){  
            return (
                <VStack {...boxProps}>
                    <FormControl id={name} isInvalid={isInvalid}>
                        <FormLabel>{label}</FormLabel>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                color='gray.300'
                            >
                                {inputLeftElement}
                            </InputLeftElement>
                            <Input placeholder={placeholder}  max={max} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled}  onPaste={onPaste} />

                        </InputGroup>
                        <FormattedFormErrorMessage error={error} />
                    </FormControl>
                </VStack>
            )

        } else {
            return (
                <VStack  flexGrow={1} {...boxProps}>
                    <FormControl id={name} isInvalid={isInvalid}>
                        <FormLabel>{label}</FormLabel>
                        <Input placeholder={placeholder} max={max} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled}  onPaste={onPaste} />
                        <FormattedFormErrorMessage error={error} />
                    </FormControl>
                </VStack>
            )
        }
    }
}