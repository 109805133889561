import React, {useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toUpper } from 'lodash';
import { REQUEST_TYPES } from '../_config';
import { motion } from 'framer-motion';
//import cn from 'classnames';

import { FormattedMessage, useIntl } from 'react-intl';

import { localeFormatDate } from 'src/_helpers';
//import { storage } from 'src/_helpers/storage';

//import { MdPlaylistAddCheck } from 'react-icons/md';
import { Flex, Switch, Box, Text, Button, Link, FormLabel,
    MenuButton, MenuList, MenuItem, Menu, 
    AccordionItem, AccordionButton, AccordionPanel, AccordionIcon 
  } from '@chakra-ui/react';

// redux
import {
  getCurrentRequestId
} from './_redux/selectors';

// components
import { LoadingEllipsis } from '../_components/Loaders';

// action
import { actions as requestActions } from '../PatientRequests/_redux/actions';

// selectors
import { getIntlMessages, getIntlLocale, getProfileIsStaff } from '../App/_redux/selectors';
import { getPatientRequestsById } from '../PatientRequests/_redux/selectors';

// styles
import { StyledButton, OverflowTextContainer } from '../_css/styles';
import { usePatientRequests } from './api/getPatientRequests';
import { NoPendingReportIcon } from 'src/_images/icons/NoPendingReportIcon';
import { NoReportsFoundIcon } from 'src/_images/icons/NoReportsFound';
import ArrowForwardIcon from 'src/_images/icons/ArrowForwardIcon';
import { useAuth } from 'src/_libs/auth';
import { useAppConfig } from 'src/App/state/appConfig';
import ThreeVerticalDotsIcon from 'src/_images/icons/ThreeVerticalDotsIcon';
import { CloseIcon } from 'src/_images/icons/CloseIcon';
import { RedoIcon } from 'src/_images/icons/RedoIcon';
import ArchiveIcon from 'src/_images/icons/ArchiveIcon';
import { CancelConfirmationDialog } from 'src/RequestListPage/components/CancelConfirmationDialog';
import { ArchiveConfirmationDialog } from 'src/RequestListPage/components/ArchiveConfirmationDialog';
import { UnsendConfirmationDialog } from 'src/RequestListPage/components/UnsendConfirmationDialog';
import { HistoryModal } from 'src/Reports/components/ReportHistoryModal';
import HistoryIcon from 'src/_images/icons/HistoryIcon';


const gridTemplateColumns = "1fr 2fr 2fr 4fr 2fr 2fr 1fr";
const gridGap = "20px";

const MENU_ITEM_ATTRS = {
  py: '8px',
}

const StyledRequestTaskHeader = styled.div`
    font-size:14px;
    padding-left: 31px;
    padding-right: 31px;
    background: transparent;
    box-sizing: border-box;
    border-radius: 3px;
    display: grid;
    color: #273238;
    text-transform: uppercase;

    grid-template-columns:  ${gridTemplateColumns};

    -ms-grid-gap: ${gridGap};
    grid-gap: ${gridGap};

    > div {
      text-align: center;
      display:flex;
    }
`

const StyledRequestTask = styled(motion.div)`
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  display: grid;

  grid-template-columns: ${gridTemplateColumns};
  grid-gap: ${gridGap};


  &:not(:disabled):not(.status.COMPLETED):hover{
    background-color: #EDF3F6;
    cursor: pointer;
  }

  .longText{
    white-space: nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
  }

  .status{    
    &:after {content:'';display:inline-block;width:10px;height:10px;border-radius:10px;vertical-align:middle;margin-left:7px;margin-right:12px;}
    &.PENDING::after{background-color:#FFA05C}
    &.COMPLETE::after{background-color:#35BE9D}
    &.COMPLETED::after{background-color:#35BE9D}
  }


  > div {
    display:flex;
    align-items:center;
  }

`;

const Styles = styled.div`
  display:flex;
  flex-direction: column;

  .sectionTitle.tasksTitle{
    padding: 25px 15px 10px;
    display:flex;
    justify-content: space-between;
    box-shadow: none;
    background: none;
    width: 100%;
  }

  .sectionContent{
    padding: 15px;
    display: flex;
    flex-direction: column;
    min-height: 150px;

    ${StyledRequestTask}{
      margin-bottom: 10px;
    }

    ${StyledButton}{
      margin: 0;
      min-width: 120px;
      font-size: 12px;
      padding: 12px 5px
    }
  }
`;

const getRequestCountString = (requests) => {
  if (requests?.total > 5){
    return ' ( 5+ )';
  } else if (requests?.total >= 0) {
    return ' ( ' + requests?.total + ' )';
  } else {
    return ' ( 0 )'
  }
}

const TaskLabel = ({taskType}) => {
  if (taskType == REQUEST_TYPES.VIDEOCHAT){
    return (
      <FormattedMessage 
        id={'openRequest.item.type.VIDEOCHAT'}
        defaultMessage={'Video Chat'}
      />)
  } else {
    return (
      <FormattedMessage 
        id={'openRequest.item.type.REPORT'}
        defaultMessage={'Report'}
      />)
    }
}

const ThreeDotMenuPending = ({onCancel, handleOpenHistory}) => { 

  const handleCancel = () => {
    onCancel();
  } 

  return (
      <Flex onClick={(event) => event.stopPropagation()}>
        <Menu>
          <MenuButton p={'8px'}>
            <ThreeVerticalDotsIcon />
          </MenuButton>
          <MenuList>
            <MenuItem {...MENU_ITEM_ATTRS} color={'#3E5159'} onClick={handleOpenHistory} icon={<HistoryIcon width={16} height={16} color={'#3E5159'} />}>
              <FormattedMessage 
                id={'requestListPage.requestList.actions.viewHistory'}
                defaultMessage={'View History'}
              />
            </MenuItem>
            <MenuItem {...MENU_ITEM_ATTRS} color={'#3E5159'} onClick={handleCancel} icon={<CloseIcon width={14} height={14} color={'#3E5159'} />}>
              <FormattedMessage 
                id={'requestListPage.requestList.actions.cancel'}
                defaultMessage={'Cancel Request'}
              />
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    )
}

const ThreeDotMenuCompleted = ({onUnsend, onArchive, handleOpenHistory}) => { 

  const handleUnsend = () => {
    onUnsend && onUnsend();
  }

  const handleArchive = () => {
    onArchive && onArchive();
  }

  return (
      <Flex onClick={(event) => event.stopPropagation()}>
        <Menu>
          <MenuButton p={'8px'}>
            <ThreeVerticalDotsIcon />
          </MenuButton>
          <MenuList>
            <MenuItem {...MENU_ITEM_ATTRS} color={'#3E5159'} onClick={handleOpenHistory} icon={<HistoryIcon width={16} height={16} color={'#3E5159'} />}>
              <FormattedMessage 
                id={'requestListPage.requestList.actions.viewHistory'}
                defaultMessage={'View History'}
              />
            </MenuItem>
            <MenuItem {...MENU_ITEM_ATTRS} color={'#3E5159'}  onClick={handleUnsend} icon={<RedoIcon  color={'#3E5159'}  />}>
              <FormattedMessage 
                id={'requestListPage.requestList.actions.resetPending'}
                defaultMessage={'Update Status: Pending'}
              />
            </MenuItem>
            <MenuItem {...MENU_ITEM_ATTRS} color={'#3E5159'}  onClick={handleArchive} icon={<ArchiveIcon  color={'#3E5159'}  />}>
              <FormattedMessage 
                id={'requestListPage.requestList.actions.archive'}
                defaultMessage={'Archive Request'}
              />
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    )
}

const CompletedTaskContainer = ({task, handleTaskClick, handleOpenHistory, onUnsend, onArchive, onPatientRecordClick}) => {
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const isStaff = useSelector(getProfileIsStaff);

  const handleViewReport = () => {
    handleTaskClick && handleTaskClick(task.unique_id, task.type, "COMPLETE", task?.report?.unique_id)
  }

  const handleViewScans = () => {
    onPatientRecordClick && onPatientRecordClick(task?.record?.unique_id)
  }


  const handleUnsend = () => {
    onUnsend && onUnsend(task?.report?.unique_id);
  }

  const handleArchive = () => {
    onArchive && onArchive(task?.unique_id);
  }


  if (task){
      // some reports have status 0, but sent_at has a date, so they should be marked as completed
    let requestStatus = task?.report?.status;
    if (task?.report?.sent_at != null){
      requestStatus = 3;
    } else if (task?.report === null){
      requestStatus = 0;
    }
    return (
      <StyledRequestTask 
        completed={true}
        className={'status COMPLETED'}
      >
        <div>{task.id}</div>
        <div><TaskLabel taskType={task.type}/></div>
        <div>{localeFormatDate(task.created_at, intlLocale, "l LT")}</div>
        <OverflowTextContainer>{task.remark}</OverflowTextContainer>
        <div className={`${toUpper(task.status)} status`}>{intlMessages[`requestListPage.requestList.status.${
                                isStaff ? 'admin' : 'dent'
                              }.${
                                requestStatus// will be undefined if no report has been started
                              }`]}
        </div>
        <div>
          <Flex w={'full'}>
              <Button
                size={'sm'}
                variant={'outline'}
                isDisabled={task?.record == null}
                onClick={ handleViewScans}
                align={'flex-end'}
              >
                <Text textTransform={'capitalize'} fontSize={"sm"} lineHeight={0}>
                  <FormattedMessage
                    id={'patientDetailPage.details.completedrequests.button.viewScans'}
                    defaultMessage={'View Scans'}
                  />
                </Text>
              </Button>
              <Box w={'8px'} />
              <Button
                size={'sm'}
                variant={'outline'}
                isDisabled={!task.report?.report_upload?.file}
                onClick={handleViewReport}
              >
                <Text textTransform={'capitalize'} fontSize={"sm"} lineHeight={0}>
                  <FormattedMessage 
                    id={'patientDetailPage.details.completedrequests.button.viewReport'}
                    defaultMessage={'View Report'}
                  />
                </Text>
              </Button>
          </Flex>
        </div>
        <div>
          <ThreeDotMenuCompleted 
            onUnsend={handleUnsend}
            onArchive={handleArchive}
            handleOpenHistory={handleOpenHistory}
          />
        </div>
      </StyledRequestTask>
    )
  } else {
    return(
      <StyledRequestTask>
      </StyledRequestTask>
    )
  }
}

const RequestTaskContainer = ({task, handleTaskClick, onPatientRecordClick, setHistoryReportId=null,  onCancel=null, onUnsend=null, onArchive=null}) => {
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const isStaff = useSelector(getProfileIsStaff);


  const handleCancel = () => {
    onCancel(task?.unique_id);
  }

  const handleOpenHistory = () => {
    setHistoryReportId({reportId: task?.report?.unique_id, requestId: task?.unique_id});
  }

  if (task){
      // some reports have status 0, but sent_at has a date, so they should be marked as completed
    let requestStatus = task?.report?.status;
    if (task?.report?.sent_at != null){
      requestStatus = 3;
    } else if (task?.report === null){
      requestStatus = 0;
    }
    // completed tasks should be shown differently
    if (task.status == 'COMPLETE' || task.status == 'COMPLETED' || requestStatus == 3){
      return (
        <CompletedTaskContainer 
          task={task} 
          handleTaskClick={handleTaskClick} 
          onPatientRecordClick={onPatientRecordClick} 
          onUnsend={onUnsend}
          onArchive={onArchive}
          handleOpenHistory={handleOpenHistory}
        />
      )
    }

    return(
      <StyledRequestTask 
        completed={task.status == 'COMPLETE' || task.status == 'COMPLETED'}
        onClick={() => handleTaskClick(task.unique_id, task.type, task.status, task?.report?.unique_id)} 
      >
        <div>{task.id}</div>
        <div><TaskLabel taskType={task.type}/></div>
        <div>{localeFormatDate(task.created_at, intlLocale, "l LT")}</div>
        <OverflowTextContainer>{task.remark}</OverflowTextContainer>
        <div className={`${toUpper(task.status)} status`}>{intlMessages[`requestListPage.requestList.status.${
                                isStaff ? 'admin' : 'dent'
                              }.${
                                requestStatus// will be undefined if no report has been started
                              }`]}</div>
        <div>
          { (requestStatus == 3) 
            ? <div />
            : (
              <Button
                size={'sm'}
                isDisabled={(task.status == 'COMPLETE' || task.status == 'COMPLETED') && task.type != "REPORT"}
                lineHeight={0}
              >
                { intlMessages[`requestDetailPage.requestTasks.task.button.${task.type}.label`]}
              </Button>
            )

          }
         
        </div>
        <div>
          <ThreeDotMenuPending
            onCancel={handleCancel}
            handleOpenHistory={handleOpenHistory}
          />
        </div>
      </StyledRequestTask>
    )
  } else {
    return(
      <StyledRequestTask>
        <LoadingEllipsis />
      </StyledRequestTask>
    )
  }
}

export const ShowHistoryButton = ({isChecked, onChange}) => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <Flex align={'center'} alignSelf={'flex-end'}>
      <FormLabel margin={0} mr={2} color={'none'} htmlFor='showHistory'>{intlMessages['requestDetailPage.requestTasks.heading.button.history'] + '?'}</FormLabel>
      <Switch margin={0} size={'sm'} id={'showHistory'} colorScheme={'brand'} 
        isChecked={isChecked} onChange={() => onChange(!isChecked)} />
    </Flex>
  )
}

const RequestTaskHeader = () => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <StyledRequestTaskHeader>
      <div>
        <FormattedMessage 
          id={'requestListPage.requestList.header.id'}
          defaultMessage={'ID'}
        />
      </div>
      <div>{(intlMessages['requestListPage.requestList.header.type'])}</div>
      <div>{(intlMessages['requestListPage.requestList.header.sent_at'])}</div>
      <div>{(intlMessages['requestListPage.requestList.header.remark'])}</div>
      <div>{(intlMessages['requestListPage.requestList.header.status'])}</div>
      <div />
      <div />
    </StyledRequestTaskHeader>
  )
}

export const CompletedTasksSection = ({ data, patientData, isLoading, onPatientRecordClick, onUpdateTasks,  onReviewClick=null}) => {
  //const intlMessages = useSelector(getIntlMessages);
  const [ archiveRequestId, setArchiveRequestId ] = React.useState(null);
  const [ unsendReportId, setUnsendReportId ] = React.useState(null);
  const [ historyReportId, setHistoryReportId ] = React.useState({reportId: null, requestId: null});
  const {formatMessage} = useIntl();
  const { user } = useAuth();
  const location = useLocation();
  const history = useHistory();

  useEffect(()=>{
    return () => {}
  }, [])

  const handleTaskClick = (uid, type, status, reportUid=null) => {
     if (type == REQUEST_TYPES.VIDEOCHAT){
      let newParams = new URLSearchParams();
      newParams.set("mdl", `task.${REQUEST_TYPES.VIDEOCHAT}`);
      newParams.set("rid", uid);
      history.push((`${location.pathname}?${newParams.toString()}`));
    } else {
      // tREQUEST_TYPES.REPORT, REQUEST_TYPES.DMFT
      if (status == "COMPLETE"){
        // show pop up of review
        onReviewClick && onReviewClick(reportUid);
      } else {
        history.push((`/reports/request/${uid}`));
      }
    }
  }

  const formatClinicPatientSearchLabel = (data) => {  
    let label = formatMessage({id: 'format.fullName', defaultMessage: '{givenName} {familyName}'}, {givenName: data?.first_name, familyName: data?.last_name});
    if (data?.parent){
      label = label + ` | ` + formatMessage({id: 'format.relationshipTo', defaultMessage: '{relationship} of {relatedTo}'}, 
        {relationship: formatMessage({id: `config.relationship.${data?.relationship}`}),
        relatedTo: formatMessage({id: 'format.fullName', defaultMessage: '{givenName} {familyName}'}, {givenName: data?.parent?.first_name, familyName: data?.parent?.last_name})
      })
    }
    if (user?.is_staff){
      label = label + ` - ${data?.clinic?.name}`
    }
    return label;
  }

  const handleGoToDownloadClick = () => {
    let url = `/settings/bulk_reports?patient=${patientData?.id}&patientQuery=${formatClinicPatientSearchLabel(patientData)}`;
    history.push(url);
  }

  const _renderTitleHeader = (isExpanded) => {
    return (
      <AccordionButton _hover={{bg: 'transparent'}} _focus={{outline: 'none'}} py={'30px'}>
        <Flex justify={'space-between'} w={'full'}>
          <Flex justify={'space-between'} align={'center'} w={'full'}>
            <Text color={"#000"} fontSize={"xl"}>
              <FormattedMessage
                id={'patientDetailPage.details.completedrequests.title'}
                defaultMessage={'Completed Requests'}
              />
            </Text>
            <Flex align={'center'}>
              {isExpanded && (
                <Link 
                  onClick={handleGoToDownloadClick}
                  mr={'25px'}
                >
                  <Flex align={'center'}>
                    <Text color={'#273238'} lineHeight={0}>
                      <FormattedMessage
                        id={'patientDetailPage.details.completedrequests.button.download'}
                        defaultMessage={'Download Reports'}
                      />
                    </Text>
                    <Flex align={'flex-start'} ml={'5px'}>
                      <ArrowForwardIcon width={18} height={18} color={'#212529'}/>
                    </Flex>
                  </Flex>
                </Link>
              )}
              <AccordionIcon ml={'10px'} color={'#405159'} />
            </Flex>
          </Flex>
        </Flex>
      </AccordionButton>
    )
  }

  if (isLoading){
    return (
      <Styles>
        <AccordionItem borderTop={'none'} borderBottom={'none'}>
          { ({ isExpanded }) => {
            return (
              <>
                {_renderTitleHeader(isExpanded)}
                <AccordionPanel pt={'10px'} pb={'20px'} px={'0'}>
                  <RequestTaskHeader />
                  <div className={'sectionContent taskList'}>
                      <div className={'loadingSection'}><LoadingEllipsis /></div>
                  </div>
                </AccordionPanel>
              </>
            )
          }}
        </AccordionItem>
      </Styles>
    )
  }

  if ((data?.patient_requests || []).length == 0){
    return (
      <Styles>
        <AccordionItem borderTop={'none'} borderBottom={'none'}>
          { ({ isExpanded }) => {
            return (
              <>
                {_renderTitleHeader(isExpanded)}
                <AccordionPanel pt={'10px'} pb={'20px'} px={'0'}>
                  <Flex py={'30px'} flexGrow={1} direction={'column'} align={'center'} justify={'center'}>
                    <NoReportsFoundIcon />
                    <Text mt={'5px'} fontSize={14} color={'none.500'}>
                      <FormattedMessage 
                        id={'patientDetailsPage.details.completedrequests.noTasks.description'}
                        defaultMessage={'No Reports Found'}
                      />
                    </Text>
                  </Flex>
                </AccordionPanel>
              </>
            )
          }}
        </AccordionItem>
      </Styles>
    )
  }
  return (
    <Styles>
      <HistoryModal 
        isOpen={historyReportId?.reportId != null}
        onClose={() => setHistoryReportId({reportId: null, requestId: null})}
        requestUid={historyReportId?.requestId}
        reportUid={historyReportId?.reportId}
      />
      <ArchiveConfirmationDialog 
        isOpen={archiveRequestId != null}
        onClose={() => setArchiveRequestId(null)}
        requestId={archiveRequestId}
        onSuccess={onUpdateTasks}

      /> 
      <UnsendConfirmationDialog
        isOpen={unsendReportId != null}
        onClose={() => setUnsendReportId(null)}
        reportUid={unsendReportId}
        onSuccess={onUpdateTasks}
      />
      <AccordionItem borderTop={'none'} borderBottom={'none'}>
          { ({ isExpanded }) => {
            return (
              <>
                {_renderTitleHeader(isExpanded)}
                <AccordionPanel py={'20px'} px={'0'}>
                  <RequestTaskHeader />
                  <div className={'sectionContent taskList'}>
                      <div>
                        {(data?.patient_requests || []).map( (elem) => (
                              <RequestTaskContainer
                                key={`completedTask_${elem.id}`}
                                task={elem}
                                handleTaskClick={handleTaskClick}
                                onPatientRecordClick={onPatientRecordClick}
                                setHistoryReportId={setHistoryReportId}
                                onUnsend={setUnsendReportId}
                                onArchive={setArchiveRequestId}

                                />
                        ))}
                      </div>
                  </div>
                </AccordionPanel>
              </>
            )
          }}
      </AccordionItem>
    </Styles>
  )
}

export const RequestTasksSection = ({data, isLoading, onPatientRecordClick, onUpdateTasks, onReviewClick=null}) => {
  const dispatch = useDispatch()
  //const intlMessages = useSelector(getIntlMessages);
  const [ cancelRequestId, setCancelRequestId ] = React.useState(null);
  const [ historyReportId, setHistoryReportId ] = React.useState({reportId: null, requestId: null});
  const location = useLocation();
  const history = useHistory();

  //const [ requests, setRequests ] = useState([]);
  const currentRequestUid = useSelector(getCurrentRequestId);
  const patientRequests = useSelector(getPatientRequestsById);

  useEffect(()=>{
    return () => {}
  }, [])

  useEffect(()=>{
    if (currentRequestUid && patientRequests[currentRequestUid] == null){
      dispatch(requestActions.fetchRequest(currentRequestUid))
    }
  }, [currentRequestUid])

  const handleTaskClick = (uid, type, status, reportUid=null) => {
     if (type == REQUEST_TYPES.VIDEOCHAT){
      let newParams = new URLSearchParams();
      newParams.set("mdl", `task.${REQUEST_TYPES.VIDEOCHAT}`);
      newParams.set("rid", uid);
      history.push((`${location.pathname}?${newParams.toString()}`));
    } else {
      // tREQUEST_TYPES.REPORT, REQUEST_TYPES.DMFT
      if (status == "COMPLETE" || status == "COMPLETED"){
        // show pop up of review
        onReviewClick && onReviewClick(reportUid);
      } else {
        history.push((`/reports/request/${uid}`));
      }
    }
  }

  const _renderTitleHeader = () => {
    return (
      <AccordionButton _hover={{bg: 'transparent'}} _focus={{outline: 'none'}} py={'30px'}>
        <Flex justify={'space-between'} align={'center'} w={'full'}>
          <Text color={"#000"} fontSize={"xl"}>
            <FormattedMessage
              id={'patientDetailPage.details.pendingrequests.title'}
              defaultMessage={'Pending Requests'}
            />
            {getRequestCountString(data)}
          </Text>
          <Flex align={'center'}>
            <AccordionIcon ml={'10px'} color={'#405159'} />
          </Flex>
        </Flex>
      </AccordionButton>
    )
  }

  if (isLoading){
    return (
      <Styles>
        <AccordionItem borderTop={'none'}>
          {_renderTitleHeader()}
          <AccordionPanel pt={'10px'} pb={'20px'} px={'0'}>
            <RequestTaskHeader />
            <div className={'sectionContent taskList'}>
                <div className={'loadingSection'}><LoadingEllipsis /></div>
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Styles>
    )
  }

  if ((data?.patient_requests || []).length == 0){
    return (
      <Styles>
      <AccordionItem borderTop={'none'}>
        {_renderTitleHeader()}
          <AccordionPanel pt={'10px'} pb={'20px'} px={'0'}>
          <Flex py={'30px'} flexGrow={1} direction={'column'} align={'center'} justify={'center'}>
              <NoPendingReportIcon />
              <Text mt={'5px'}  fontSize={14} color={'none.500'}>
                <FormattedMessage 
                  id={'patientDetailsPage.details.pendingrequests.noTasks.description'}
                  defaultMessage={'No Pending Requests'}
                />
              </Text>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      </Styles>
    )
  }

  return (
    <Styles>
      <HistoryModal 
        isOpen={historyReportId?.reportId != null}
        onClose={() => setHistoryReportId({reportId: null, requestId: null})}
        requestUid={historyReportId?.requestId}
        reportUid={historyReportId?.reportId}
      />
      <CancelConfirmationDialog 
        isOpen={cancelRequestId != null}
        onClose={() => setCancelRequestId(null)}
        requestId={cancelRequestId}
        onSuccess={onUpdateTasks}
      />
      <AccordionItem borderTop={'none'}>
        {_renderTitleHeader()}
          <AccordionPanel pt={'10px'} pb={'20px'} px={'0'}>
          <RequestTaskHeader />
          <div className={'sectionContent taskList'}>
            <div>
              {(data?.patient_requests || []).map( (elem) => (
                    <RequestTaskContainer
                      key={`pendingTask_${elem.id}`}
                      task={elem}
                      handleTaskClick={handleTaskClick}
                      onPatientRecordClick={onPatientRecordClick}
                      setHistoryReportId={setHistoryReportId}
                      onCancel={setCancelRequestId}
                      />
              ))}
            </div>
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Styles>
  )
}

export const PatientTasksSection = ({patientUid, patientData, onReviewClick, onPatientRecordClick}) => {
    const clinic = useAppConfig(state => state.clinic?.id) 
    const { data: pendingData, isLoading: isPendingLoading, isFetching: isPendingFetching, refetch: refetchPending } = usePatientRequests({patientUid, status: 'PENDING', clinic});
    const { data: completeData, isLoading: isCompleteLoading, isFetching: isCompleteFetching, refetch: refetchCompleted } = usePatientRequests({patientUid, status: 'COMPLETE', clinic});

    const handleUpdateTasks = () => {
      refetchPending();
      refetchCompleted();
    }

    return (
      <>
        <RequestTasksSection 
          isLoading={isPendingLoading || isPendingFetching}
          data={pendingData}
          patientUid={patientUid}
          onReviewClick={onReviewClick}
          onPatientRecordClick={onPatientRecordClick}
          onUpdateTasks={handleUpdateTasks}
        />
        <CompletedTasksSection 
          isLoading={isCompleteLoading || isCompleteFetching}
          data={completeData}
          patientUid={patientUid}
          patientData={patientData}
          onReviewClick={onReviewClick}
          onPatientRecordClick={onPatientRecordClick}
          onUpdateTasks={handleUpdateTasks}
        />
      </>
    )

}