import React from 'react';
import {  Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Box } from '@chakra-ui/react';
import styled from 'styled-components'
import { upperFirst } from 'lodash';

import Alignment from '../_components/Alignment/Alignment';
//import { ChannelService } from '../ChannelIO';
//import { GoogleLogin } from 'react-google-login';

import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { useAuth } from 'src/_libs/auth';
import { getIntlMessages } from 'src/App/_redux/selectors';
import PasswordShowIcon from 'src/_images/icons/PasswordShowIcon';
import PasswordHideIcon from 'src/_images/icons/PasswordHideIcon';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { getFinalFormMetaError, isFinalFormMetaInvalid } from 'src/_libs/forms';

/*ReactDOM.render(
  <GoogleLogin
    clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
    buttonText="Login"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />,
  document.getElementById('googleButton')
);*/

const Page = styled.div`
  height:100vh;
`;

/*const StyledButton = styled.button`
  background: #273238;
  box-shadow: 0px 2px 14px rgba(39, 50, 56, 0.3);
  border-radius: 28px;
  color:white;
  padding: 10px 15px;
  font-weight: 700;
  width: fit-content;
  min-width: 200px;
  margin: 0 auto;
  margin-top: 30px;
`;*/

const StyledForm = styled.form`
  width: 400px;

  background: #F7F9FA;
  border: 1px solid #EDEDED;
  box-shadow: 1px 3px 20px rgba(239, 239, 239, 0.5);
  border-radius: 10px;

  text-align:center;

  display:flex;
  flex-direction: column;
  padding: 20px 50px 40px;
`;

const FormHeader = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  margin: 20px 0 30px 0;
`;

export const StyledInputWithOverlay = styled.div`
  position:relative;
    width:100%;
  > div{
    position:absolute;
    display:flex;
    align-items:center;
    top: 0;
    bottom: 0;
    right: 0;
    height: 43px;
    padding: 15px 0;
    padding-right: 10px;


    &:hover{
      cursor:pointer;
      opacity: 0.8;
    }
  }
`;


export const StyledForgotPasswordLink = styled(Link)`
  font-family: Proxima Nova Semibold;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  display:flex;
  justify-content:center;

  &:not(:first-child){
    margin-top: 20px;
  }

  text-align: center;
  letter-spacing: 0.9px;

  text-decoration: none;

  color: #405159;
`;


export const LoginPage = () => {
  const { login } = useAuth();
  const location = useLocation();
  const [passwordHidden, setPasswordHidden] = React.useState(true);
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);

  const BOX_PROPS = {
    mt: ['10px'],
  }

  //const [verificationCode, setVerificationCode] = React.useState('');

  const handleLogin = async (values) => {
    try{
      await login(values);
      if (location.state != undefined && location.state.from != undefined){
          history.push(location.state.from.pathname);
      } else {
          history.push("/");
      }
    } catch (err){
      console.log("err ", err);
      return {
        ...err,
        [FORM_ERROR] : upperFirst(intlMessages['form.error.general.error'])
      }
    }
  }

  const PasswordShowHideButton = ({passwordHidden, setPasswordHidden}) => {
    return (
      <Box _hover={{cursor: 'pointer'}} onClick={() => setPasswordHidden(old => !old)}>
        { passwordHidden ? <PasswordShowIcon /> : <PasswordHideIcon />  }
      </Box>
    )
  }

  const _renderLoginForm = () =>{
    return(
      <Alignment
        horizontal={Alignment.horizontal.CENTER}
        vertical={Alignment.horizontal.CENTER}
      >
        <Form
          onSubmit={handleLogin}
          render = {({
            handleSubmit,
            submitting,
            values,
          }) => (
            <StyledForm
              onSubmit={handleSubmit}
              >
                <FormHeader>
                  {intlMessages['login.form.title']}
                </FormHeader>
                <Field name="email" parse={v => v}>
                  {({ input, meta}) => (
     
                    <InputComponent
                      boxProps={BOX_PROPS}
                      label={intlMessages['login.form.email']}
                      placeholder={upperFirst(intlMessages['login.form.email'])}
                      {...input}
                      type="text"
                      isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                      error={getFinalFormMetaError(meta)}
                    />
                  )}
                </Field>
                <Field name="password" parse={v => v}>
                  {({ input, meta}) => (
                    <InputComponent
                      boxProps={BOX_PROPS}
                      label={intlMessages['login.form.password']}
                      placeholder={upperFirst(intlMessages['login.form.password'])}
                      {...input}
                      type={passwordHidden ? "password" : "text"}
                      isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                      error={getFinalFormMetaError(meta)}
                      inputRightButton={PasswordShowHideButton({passwordHidden, setPasswordHidden})}
                    />
                  )}
                </Field>
                <Button
                  mt={['20px']}
                  variant={'solid'}
                  size={'lg'}
                  type="submit"
                  isLoading={submitting}
                  isDisabled={!(values?.email && values?.password )}
                >
                  <FormattedMessage
                    id={'login.form.submit'}
                    defaultMessage={'Login'}
                  />
                </Button>
                <StyledForgotPasswordLink to={"/reset"}>{intlMessages['login.form.forgetpassword.text']}</StyledForgotPasswordLink>
            </StyledForm>
          )}
        /> {/* <Form /> */}
      </Alignment>
    )
  }


  return (
        <Page>
            {_renderLoginForm()}
        </Page>
      )
}
