import React from 'react';
import { 
    Flex,
    FormControl, 
    FormLabel, 
    FormErrorMessage,
    Checkbox
  } from '@chakra-ui/react';
import { HelpPopover } from './HelpPopover';
  
  export const CheckboxComponent = ({name, value, onChange, label, isInvalid, error, isDisabled=false, colorScheme='brand', helpDescription=null}) => {
    const handleChange = () => {
      onChange(!value)
    }

    return (
      <FormControl id={name} isInvalid={isInvalid} colorScheme={colorScheme}>
        <Flex>
          <Checkbox 
            isChecked={value}
            onChange={handleChange}
            size="lg"
            isDisabled={isDisabled}
            colorScheme={colorScheme}
          >
          <FormLabel m={0} ml={'5px'}>{label}</FormLabel>
        </Checkbox>
          {helpDescription && <HelpPopover title={label} description={helpDescription} />}
        </Flex>
        {
            isInvalid && <FormErrorMessage>{error ? error  : ""}</FormErrorMessage>
        }
        
      </FormControl>
    )
  }