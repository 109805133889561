import React from 'react';
import { Form, Field } from 'react-final-form';

import { cloneDeep } from "lodash";
import {
    Box, SimpleGrid, Button, 
    // eslint-disable-next-line no-unused-vars
    FormControl, FormLabel, FormErrorMessage, VStack, Input
} from '@chakra-ui/react';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required, mustBePhoneNumber, convertToAPIValues } from 'src/_libs/forms';
import { useSelectOptions } from 'src/App/api/getSettings';

import { services } from './_redux/services';
import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { usePatientDetails, updateQueryPatientDetails } from './api/getPatientDetails';
import { useAppConfig } from 'src/App/state/appConfig';
import { CenteredSpinner } from 'src/_components/Loaders/CenteredSpinner';
import { ClinicPatientSearch } from './ClinicPatientSearch';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';

const formatSearchValue = (values) => {
    // currently only parent is the value
    const newValues = cloneDeep(values);
    if (values.parent){
        newValues.parent = {
            ...values.parent,
            value: values.parent.id,
            label: "",
            data: values.parent
        }
    }
    if (values.gender){
        newValues.gender = {
            value: values.gender,
            label: values.gender
        }
    }
    return newValues;
}

// Edit user is much different from create so new form is made
export const EditPatientForm = ({
    onClose,
    patientUid,
    onSuccess=null,
}) => {
    const clinic = useAppConfig(state => state.clinic?.id);
    const { data, isLoading, isFetching, isError, error } = usePatientDetails({patientUid, clinic});
    const { data : gendersData, isLoading : gendersIsLoading, isFetching : gendersIsFetching } = useSelectOptions({'option': 'genders'});
    const { data: relationshipsData, isLoading: relationshipsIsLoading, isFetching: relationshipsIsFetching } = useSelectOptions({'option': 'relationships'});
    const { formatMessage } = useIntl();
    const defaultData = React.useMemo(() => [], []);
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        // when there is no parent, then the relationship is SELF
        if (!convertedValues.parent){
            convertedValues.relationship = "SELF"
        }
        try{
            const result = await services.updatePatient(patientUid, convertedValues, clinic);
            updateQueryPatientDetails(patientUid, result)
            onSuccess && onSuccess(result);
            onClose();
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    // name, birthday, gender, parent/guardian, 
    // external_id

    if (isError){
        return (
            <Box>
                <ErrorNotFound error={error} size={"md"} buttonType={'refresh'} />
            </Box>
        )
    }

    if (isLoading || isFetching){
        return ( 
            <CenteredSpinner />
        )
    } else {

        /*let original_user_name = formatMessage(
            {id: 'format.fullName', defaultMessage: '{givenName} {familyName}'}, 
            {givenName: data?.patient?.ploveruser?.first_name, familyName: data?.patient?.ploveruser?.last_name}
        )
        if (data?.patient?.parent?.id){
            original_user_name += ' | ' + formatMessage(
                {id: 'format.relationshipTo', defaultMessage: '{relationship} of {relatedTo}'},
                {
                    relationship: formatMessage({id: `config.relationship.${data?.patient?.relationship}`}),
                    relatedTo: formatMessage(
                        {id: "format.fullName"}, 
                        {givenName: data?.patient?.parent?.first_name, familyName: data?.patient?.parent?.last_name}
                    )
                }
            )
        }*/
        return (
            <>
                {/*<SimpleGrid columns={[1]} spacing={colSpacing}  mt={rowSpacing}>
                    <VStack>
                        <FormControl id={'email'}>
                            <FormLabel>
                                <FormattedMessage
                                    id="editPatientForm.field.ploveruser.label"
                                    defaultMessage="Original Name"
                                />
                            </FormLabel>
                            <Input type={"text"} isDisabled={true} value={original_user_name}/>
                            <FormErrorMessage>{''}</FormErrorMessage>
                        </FormControl>
                    </VStack>
                </SimpleGrid>*/}
                <Form
                    onSubmit={handleSubmit}
                    initialValues={formatSearchValue({...data?.patient})}
                    mutators={{ 
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        }
                    }}
                    validate={(values) => {
                        const errors = {};

                        if (values?.parent?.value && !values.relationship){
                            errors.relationship =  formatMessage({id: 'editPatientForm.field.relationship.required', defaultMessage: 'required'})
                        }
                        return errors ;
                    }}
                    render = {
                        ({
                            handleSubmit,
                            values,
                            submitting,
                        }) => {
                            return (
                                <Box as={'form'} onSubmit={handleSubmit}>
                                
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="parent">
                                        {({input, meta}) => (
                                            <ClinicPatientSearch  
                                                input={input}
                                                meta={meta}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                label={formatMessage({id: 'editPatientForm.field.accountrole.main_dentist.label', defaultMessage: 'Parent/Guardian'})}
                                                placeholder={formatMessage({id: 'editPatientForm.field.accountrole.main_dentist.placeholder', defaultMessage: 'Search for a Patient'})}
                                                onSelect={(val) => {
                                                    input.onChange(val);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <Field name="relationship">
                                        {({input, meta}) => (
                                            <SelectOptionField 
                                                isDisabled={!values?.parent?.value ? true : false}
                                                isLoading={relationshipsIsLoading || relationshipsIsFetching}
                                                label={formatMessage({id: 'editPatientForm.field.country.label', defaultMessage: 'Relationship'})}
                                                placeholder={formatMessage({id: 'editPatientForm.field.country.label', defaultMessage: 'Relationship'})}
                                                input={input}
                                                selectOptions={relationshipsData?.options}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={!(!values?.parent?.value ? true : false) && meta?.error}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>

                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="first_name" validate={required}>
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.first_name.label', defaultMessage: 'First Name'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.first_name.placeholder', defaultMessage: 'First Name'})}
                                                    {...input}
                                                   isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="last_name" >
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.last_name.label', defaultMessage: 'Last Name'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.last_name.placeholder', defaultMessage: 'Last Name'})}
                                                    {...input}
                                                   isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="external_id">
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.external_id.label', defaultMessage: 'External ID'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.external_id.placeholder', defaultMessage: 'External ID'})}
                                                    {...input}
                                                   isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="class_number">
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.class_number.label', defaultMessage: 'Class Number'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.class_number.placeholder', defaultMessage: 'Class Number'})}
                                                    {...input}
                                                   isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="date_of_birth">
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.date_of_birth.label', defaultMessage: 'Date of Birth'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.date_of_birth.placeholder', defaultMessage: 'Date of Birth'})}
                                                    {...input}
                                                    type="date"
                                                   isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="gender">
                                            {({input, meta}) => (
                                                <SelectOptionField 
                                                    isLoading={gendersIsFetching || gendersIsLoading}
                                                    isMultiple={false}
                                                    label={formatMessage({id: 'editPatientForm.field.gender.label', defaultMessage: 'Gender'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.gender.placeholder', defaultMessage: 'Gender'})}
                                                    input={input}
                                                    selectOptions={gendersData?.options || defaultData}
                                                   isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="phonenumber" validate={mustBePhoneNumber}>
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.phonenumber.label', defaultMessage: 'Phone Number'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.phonenumber.placeholder', defaultMessage: 'Phone Number'})}
                                                    {...input}
                                                    type={'phonenumber'}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="email">
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'editPatientForm.field.email.label', defaultMessage: 'Email Address'})}
                                                    placeholder={formatMessage({id: 'editPatientForm.field.email.placeholder', defaultMessage: 'Email Address'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                        <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                            <FormattedMessage 
                                                id="adminPage.form.cancel.button.label"
                                                defaultMessage="Cancel"
                                            />
                                        </Button>
                                        <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                            <FormattedMessage 
                                                id="adminPage.form.submit.button.label"
                                                defaultMessage="Submit"
                                            />
                                        </Button>
                                    </SimpleGrid>
                                </Box>
                            )
                        }
                    }
                />
            </>
        )
    }   
}