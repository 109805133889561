import { authHeader, handleResponse } from 'src/_helpers';
import { API_URLS } from 'src/_config';
import axios from 'axios'


export const upload = (userId, file, record, onUploadProgress, extraData = {}) => {
    let formData = new FormData();
    const requestUrl = API_URLS.createUserUploads.url.replace("{userId}", userId);
    const requestHeaders = {
      'Content-Type': "multipart/form-data",
      ...authHeader()
    }
    formData.append("file", file);
    formData.append("record", record);
    formData.append("extra_data", JSON.stringify(extraData));

    return axios.post(requestUrl, formData, {
      headers: requestHeaders,
      onUploadProgress
    });
};

export function fetchPatientUserCollection(queryParams) {
    const requestUrl = API_URLS.getAdminPatientUserCollection;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    let url = requestUrl.url + '?'  + queryParams
    return fetch(url, requestOptions).then(handleResponse);
  } 
  
export function deleteFeeSchedule(id, data={}) {
  const requestUrl = API_URLS.deleteAdminFeeSchedule;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace("{id}", id)
  return fetch(url, requestOptions).then(handleResponse);
}


export function editTreatmentCode(id, data) {
  const requestUrl = API_URLS.editAdminTreatmentCodeDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace("{id}", id)
  return fetch(url, requestOptions).then(handleResponse);
}

export function createTreatmentCode(data) {
  const requestUrl = API_URLS.createAdminTreatmentCodeCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchTreatmentCodeCollection(queryParams) {
  const requestUrl = API_URLS.getAdminTreatmentCodeCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url + '?'  + queryParams
  return fetch(url, requestOptions).then(handleResponse);
} 

export function fetchTreatmentCodeDetails(id) {
  const requestUrl = API_URLS.getAdminTreatmentCodeDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace("{id}", id)
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchReportTemplateCollection(queryParams) {
  const requestUrl = API_URLS.getAdminReportTemplateCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url + '?'  + queryParams
  return fetch(url, requestOptions).then(handleResponse);
} 



export function fetchUserCollection(queryParams) {
    const requestUrl = API_URLS.getAdminUserCollection;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    let url = requestUrl.url + '?'  + queryParams
    return fetch(url, requestOptions).then(handleResponse);
  } 
  
export function fetchUserRequestCollection(queryParams) {
    const requestUrl = API_URLS.getAdminUserRequestCollection;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    let url = requestUrl.url + '?'  + queryParams
    return fetch(url, requestOptions).then(handleResponse);
  } 
    
export function fetchClinicCollection(queryParams) {
  const requestUrl = API_URLS.getAdminClinicCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url + '?' + queryParams
  return fetch(url, requestOptions).then(handleResponse);
} 

export function fetchSelectOptions(select_option, queryParams=null) {
  const requestUrl = API_URLS.getAdminSelectOptions;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{select_option}', select_option)
  if (queryParams){
    url = url + '?' + queryParams
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function createPatientUser(data) {
  const requestUrl = API_URLS.createPatientUserAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url
  return fetch(url, requestOptions).then(handleResponse);
}

export function deleteUserRequest(requestId) {
  const requestUrl = API_URLS.deleteUserRequestAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({})
  };
  let url = requestUrl.url.replace("{requestId}", requestId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function createUserProfile(userId, data) {
    const requestUrl = API_URLS.createUserProfile;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    let url = requestUrl.url.replace('{userId}', userId)
    return fetch(url, requestOptions).then(handleResponse);
}

export function createBulkUserProfiles(data) {
    const requestUrl = API_URLS.createBulkUserProfiles;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    let url = requestUrl.url
    return fetch(url, requestOptions).then(handleResponse);
}

export function createReportTemplate(data) { 
    const requestUrl = API_URLS.createReportTemplate;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    let url = requestUrl.url
    return fetch(url, requestOptions).then(handleResponse);
}

export function createUserFavoriteDentist(userId, data) {
  const requestUrl = API_URLS.createUserFavoriteDentist;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{userId}', userId)
  return fetch(url, requestOptions).then(handleResponse);
} 

export function createAccountRole(clinicId, data) {
  const requestUrl = API_URLS.createAccountRoleAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{clinicId}', clinicId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function createDentistAccountRole(dentistId, data) {
  const requestUrl = API_URLS.createDentistRoleAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{dentistId}', dentistId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function createRecord(userId, data){
  const requestUrl = API_URLS.createUserRecord;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace("{userId}", userId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchUserFavoriteDentists(userId) {
  const requestUrl = API_URLS.getUserFavoriteDentists;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace("{userId}", userId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchUserProfiles(userId) {
  const requestUrl = API_URLS.getUserProfiles;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace("{userId}", userId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchPatientUserDetails(userId) {
  const requestUrl = API_URLS.getUserDetailsAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace("{userId}", userId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchReportTemplateDetails(id) {
  const requestUrl = API_URLS.getAdminReportTemplateDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace("{id}", id)
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchUserRequestDetails(requestId) {
  const requestUrl = API_URLS.getUserRequestDetailsAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace("{requestId}", requestId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchDentistDetails(dentistId) {
  const requestUrl = API_URLS.getDentistDetailsAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace("{dentistId}", dentistId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchDentistDetailsRoles(dentistId) {
  const requestUrl = API_URLS.getDentistDetailsRolesAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace("{dentistId}", dentistId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function editPatientUserDetails(userId, data) {
  
  const requestUrl = API_URLS.editUserAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace("{userId}", userId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function editUserRequest(requestId, data) {
  const requestUrl = API_URLS.editUserRequestAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace("{requestId}", requestId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function editDentist(dentistId, data) {
  const requestUrl = API_URLS.editDentistAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace("{dentistId}", dentistId)
  return fetch(url, requestOptions).then(handleResponse);
}
export function editReportTemplate(id, data) {
  const requestUrl = API_URLS.editReportTemplate;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace("{id}", id)
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchClinicDetails(clinicId) {
  const requestUrl = API_URLS.getClinicDetailsAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace("{clinicId}", clinicId)
  return fetch(url, requestOptions).then(handleResponse);
}


export function fetchClinicDetailsRoles(clinicId) {
  const requestUrl = API_URLS.getClinicDetailsRolesAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace("{clinicId}", clinicId)
  return fetch(url, requestOptions).then(handleResponse);
}
export function editClinic(clinicId, data) {
  const requestUrl = API_URLS.editClinicAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace("{clinicId}", clinicId)
  return fetch(url, requestOptions).then(handleResponse);
}

export function createUserRequest(data) {
  const requestUrl = API_URLS.createUserRequestAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url
  return fetch(url, requestOptions).then(handleResponse);
}

export function createClinic(data) {
  const requestUrl = API_URLS.createClinicAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url
  return fetch(url, requestOptions).then(handleResponse);
}

export function createAccount(data) {
  const requestUrl = API_URLS.createAccountAdmin;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url
  return fetch(url, requestOptions).then(handleResponse);
}