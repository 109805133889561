import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';
import { 
  Box, 
  Text,
  Flex,
  HStack,
  Skeleton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Spinner
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReadOnlyEditor } from 'src/_components/Editor';
import { ToothSetModern } from 'src/_components/ToothSet/ToothSetModern';


import { useWindowSize } from 'src/_hooks/useWindowSize';
import { CategorytDot } from 'src/Reports/components/CategoryDot';
import { CategoryTitle } from 'src/Reports/components/CategoryTitle';
import { ReportPrintPreview, PrintPreviewReportSection } from 'src/Reports/components/ReportPrintPreview';
import { useReportDetails } from 'src/Reports/api/getReport';
import { formatDate } from 'src/_helpers';
import { useReportCategories } from 'src/Reports/api/getReportCategories';
import { useAppConfig } from 'src/App/state/appConfig';
import { getReportLabel } from 'src/Reports/components/BulkReportDownload';

//import html2canvas from 'html2canvas';
//import { jsPDF } from "jspdf";

const CategoryItem = ({category}) => {
  return (
    <Flex
      border={'0.5px solid #405159'}
      borderRadius={['30px']}
      align={'center'}
      bg={'#fff'}
      px={['10px']}
      py={['4px']}
      mb={['10px']}
      mr={['10px']}
    >
      <CategorytDot category={category} width={['10px']} height={['10px']} marginRight={['8px']} />
      <Text color={'#405159'} fontSize={['12px']} textTransform={'upperCase'}>
        <CategoryTitle  category={category} />
      </Text>
    </Flex>
  )
}

const ChartCategoriesLoader = () => {
  return (
      <HStack spacing={['10px']}>
          <Skeleton height={["30px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
          <Skeleton height={["60px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
          <Skeleton height={["60px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
          <Skeleton height={["60px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
      </HStack>
  )

}

const Categories = ({reportType=null}) => {
  const { data, isLoading, isFetching } = useReportCategories({reportType})

  return (
    <Flex justify={['center']} w={['full']} px={['40px']} py={['20px']} flexWrap={['wrap']}>
      {
        (isLoading || isFetching )? (
          <ChartCategoriesLoader />
        ) : (
          (data?.categories || []).map((category) => <CategoryItem key={category.value} category={category.value} />)
        )
      }
    </Flex>
  )
}

export const ChartSection = React.forwardRef(({data, requestType}, ref) => {

    const toothClasses = {};

    // go through the block data and convert it to tooth charting object
    // object with key of tooth number "txx" and value of category in all caps for class
    (data?.blocks || []).map((block) => {
        const data = block?.data;
        data.regions.map((region) => {
          if (region.length > 0){
            // will overwrite any previous block styles
            if (toothClasses[`t${region}`]){
                toothClasses[`t${region}`].push(block.data.category.toUpperCase())
            } else {
                toothClasses[`t${region}`] = [block.data.category.toUpperCase()]
            }
          }
        })
    })

    const isDMFT = (requestType || "").indexOf("DMFT") !== -1;

    if (isDMFT){

      return( 
        <Flex  
          mr={['20px']} 
          pr={['20px']}
          bg={'#fff'}
          boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
          m={['5px']}
          borderRadius={['15px']}
          ref={ref}
        >
          <Flex 
            w={['full']}
            justify={'center'} 
            alignSelf={'center'}
            background={'#fff'}
            padding={['20px']}
            borderRadius={['25px']}
            px={['90px']}
          >
            <Flex align={['center']}>
              <Text mr={['5px']}>
                <FormattedMessage
                  id={'reportDetailsPage.reviewReportModal.toothChart.right'}
                  defaultMessage={'R'}
                  />
              </Text>
            </Flex>
            <ToothSetModern
              toothClasses={toothClasses}
              width={190}
              height={360}
            />
            <Flex align={['center']}>
              <Text ml={['5px']}>
                <FormattedMessage
                  id={'reportDetailsPage.reviewReportModal.toothChart.left'}
                  defaultMessage={'L'}
                  />
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )
    } else {

      return( 
        <Box  
          mr={['20px']} 
          pr={['20px']}
          bg={'#fff'}
          boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
          m={['5px']}
          borderRadius={['15px']}
          ref={ref}
        >
          <Flex 
            w={['full']}
            justify={'center'} 
            background={'#fff'}
            padding={['20px']}
            borderRadius={['25px']}
          >
            <Flex align={['center']}>
              <Text mr={['5px']}>
                <FormattedMessage
                  id={'reportDetailsPage.reviewReportModal.toothChart.right'}
                  defaultMessage={'R'}
                  />
              </Text>
            </Flex>
            <ToothSetModern
              toothClasses={toothClasses}
              width={190}
              height={360}
            />
            <Flex align={['center']}>
              <Text ml={['5px']}>
                <FormattedMessage
                  id={'reportDetailsPage.reviewReportModal.toothChart.left'}
                  defaultMessage={'L'}
                  />
              </Text>
            </Flex>
          </Flex>
          <Categories />
        </Box>
      )
    }
})

ChartSection.displayName = 'ChartSection';



export const ReportSection = ({data, remark}) => {
  const editorInstance = useRef(null);

  const setEditorInstance = (instance) => {
    if (editorInstance?.current == null){
        editorInstance.current = instance;
    }
}


  if (!data?.blocks || data?.blocks?.length == 0){
    return (
      <Box 
        p={0} 
        boxShadow = {'0px 0px 2px 0px rgba(0, 0, 0, 0.25)'} 
        m={['5px']}
        bg={['#fff']}
        borderRadius={['15px']}
      >
        <Skeleton height={['400px']} w={['full']} />
      </Box>
    )
  } else {
    return (
      <Box pl={['20px']}>
        <Box
          p={0} 
          boxShadow = {'0px 0px 2px 0px rgba(0, 0, 0, 0.25)'} 
          m={['5px']}
          bg={['#fff']}
          borderRadius={['15px']}
        >   
            <ReadOnlyEditor 
              editorInstance={editorInstance.current} 
              setEditorInstance={setEditorInstance} 
              data={data}
              height={400}
            />
        </Box>
        <Box>
          <Flex mt={['30px']}>
          {/*<Text fontSize={['30px']} color={'#273238'}>
            <FormattedMessage
                id="requestDetailPage.mediaColumn.patientnotes"
                defaultMessage="Patient Comments"
            />
          </Text>*/}
          </Flex>
          <Flex mt={['10px']}>
            <Text 
              color={'#405159'} 
              fontSize={['15px']}
            >
              {remark}
            </Text>
          </Flex>
        </Box>
      </Box>
    )
  }

}

/*
async function exportAsImage(el){
  try{
      const canvas = await html2canvas(el);
      const image = canvas.toDataURL("image/png", 1.0);
      return image;
  } catch(err){
      console.log(err)
      return null;
  }
}*/

export const ReportReview = ({ data, isLoading, isFetching, requestType }) => {
  if (isLoading || isFetching || data?.report?.report_data) {
      return (
        <Box>
          <Flex overflow={'auto'} maxH={['70vh']} justify={['center']}>
              <ChartSection data={data?.report?.report_data} requestType={requestType} />
              <PrintPreviewReportSection data={data?.report?.report_data} remark={data?.report?.remark} type={data?.report?.request_type} requestType={requestType} isPrint={false} />
          </Flex>
        </Box>
      );
  } else {
      return <></>;  // Return null instead of empty fragment for potentially cleaner output
  }
};

const PdfReportPreview = ({url, isLoading}) => {
  
  if (isLoading){
    return (
      <Box  
        height={'full'}
        width={'full'}
        py={'30px'} justify={'center'} align={'center'}>
        <Spinner 
          size='xl'
          thickness='4px'
          speed='0.65s' 
          color='#44C5A6' 
        />
      </Box>
    )
  } else if (!url){
    return (
      <Box
        height={'full'}
        width={'full'}
      >
        <Text>
          <FormattedMessage
            id={'reportDetailsPage.reviewReportModal.body.error'}
            defaultMessage={'Error loading PDF'}
          />
        </Text>
      </Box>
    )
  } else {
    // return the pdf viewer
  return (
    <Box
      height={'full'}
      width={'full'}
    >
      <iframe 
        src={url}
        height="100%"
        width="100%"
        title="Report PDF Viewer"
      />
    </Box>
  )
  }
}

const PatientReportPdfReviewModal = ({isOpen, onClose, reportUid }) => {
  const clinic = useAppConfig((state) => state.clinic?.id);
  const { formatMessage } = useIntl();
  const { data, isLoading, isFetching } = useReportDetails({ reportId: reportUid, clinic });
  const [ isDownloading, setIsDownloading ] = React.useState(false);
  const { ref, windowSize } = useWindowSize();

  const downloadFile = async () => {
    setIsDownloading(true);
    const fileUrl = data?.report?.report_upload?.download_url;
    if (fileUrl){
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      const file_name_format = null;
      const title = getReportLabel(data?.report?.patient, data?.report?.created_at, file_name_format, formatMessage);
      link.href = blobUrl;
      link.setAttribute('download', `${title}.pdf`);

      // Append the link to the body
      document.body.appendChild(link);
      link.click();
      // cleanup the url
      setTimeout(() => {
        URL.revokeObjectURL(blobUrl);
          try {
              document.body.removeChild(link);
          } catch (err){
              console.error(err);
          }
        }, 100);
      }
    setIsDownloading(false);
  }
  

  return (
      <Modal 
        isOpen={isOpen && reportUid} 
        onClose={onClose} 
        size={'lg'} 
        closeOnOverlayClick={false} 
      >
      <ModalOverlay />
      <ModalContent 
        ref={ref}
        height={windowSize.height*0.85}
        minW={windowSize.width*0.85}
        bg={'#F7F7F7'}
      >
          <ModalCloseButton mt={['15px']} onClick={onClose} />
          <ModalHeader bg={'#fff'} py={['30px']} filter={'drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.08))'}>
              <Text 
                  textTransform={'uppercase'}
                  color={'#1217A'} 
                  fontSize={['15px']} 
                  letterSpacing={['1px']} 
                  textAlign={'center'}
                  fontWeight={[400]}
              >
                  <FormattedMessage
                      id={'patientDetailsPage.reviewReportModal.title'}
                      defaultMessage={'Report Preview: {date}'}
                      values={{ date: formatDate(data?.report?.sent_at) }}
                  />
              </Text>
          </ModalHeader>
          <ModalBody pt={['24px']} px={['50px']} pb={'10px'} h={'100%'}>
              <PdfReportPreview 
                isLoading={isLoading || isFetching}
                url={data?.report?.report_upload?.file}
              />
          </ModalBody>
          <ModalFooter px={[0]}>
              <Box w={'full'} px={'50px'}>
                  <Flex justify={['flex-end']} py={['15px']} w={'full'}>
                      <Button 
                        variant={'outline'} 
                        mr={['5px']} 
                        size={['lg']} 
                        isLoading={isDownloading}
                        isDisabled={!data?.report?.report_upload?.download_url}
                        onClick={downloadFile}
                      >
                          <FormattedMessage
                              id={'reportDetailsPage.reviewReportModal.footer.button.downloadpdf'}
                              defaultMessage={'Download PDF'}
                          />
                      </Button>
                      <Button 
                        variant={'solid'} 
                        ml={['5px']} 
                        size={['lg']} 
                        onClick={onClose}
                      >
                          <FormattedMessage
                              id={'patientDetailsPage.reviewReportModal.footer.button.close'}
                              defaultMessage={'Close'}
                          />
                      </Button>
                  </Flex>
              </Box>
          </ModalFooter>
      </ModalContent>
    </Modal>
  )    
}

const PatientReportReviewModal = ({isOpen, onClose, reportUid }) => {
  const clinic = useAppConfig((state) => state.clinic?.id);
  const { data, isLoading, isFetching } = useReportDetails({ reportId: reportUid, clinic });

  const chartRef = useRef(null);
  //const [ imageSrc, setImageSrc ] = useState(null);
  const handlePrint = useReactToPrint({
    documentTitle: `${data?.report?.sent_at && data?.report?.sent_at.substring(0, 10)} ${data?.report?.patient_name}` ,
    content: () => chartRef.current,
  });
  
  /*useEffect(() => {
      if (chartRef.current != null){
          exportAsImage(chartRef.current).then((image) => {
              setImageSrc(image);
          })
      }
  }, [chartRef.current])

  const generatePdf = () => {
      if (!imageSrc){
        alert('error generating pdf')
        return 
      };
      const doc = new jsPDF({
          unit: 'px',
          format: 'a4',
          hotfixes: ["px_scaling"]
      });
      doc.text('Hello world!', 10, 20);
      //doc.addImage(imageSrc, 'PNG', 0, 40, 220, 360);
      doc.addImage(imageSrc, 'PNG', 0, 40, 460, 560); // trying to aqdd categories
      doc.save('a4.pdf');
  }*/

  return (
      <Modal 
        isOpen={isOpen && reportUid} 
        onClose={onClose} 
        size={'xl'} 
        closeOnOverlayClick={false} 
        isCentered={true}
      >
      <ModalOverlay />
      <ModalContent minW={'1100px'} bg={'#F7F7F7'}>
          <ModalCloseButton mt={['15px']} onClick={onClose} />
          <ModalHeader bg={'#fff'} py={['30px']} filter={'drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.08))'}>
              <Text 
                  textTransform={'uppercase'}
                  color={'#1217A'} 
                  fontSize={['15px']} 
                  letterSpacing={['1px']} 
                  textAlign={'center'}
                  fontWeight={[400]}
              >
                  {formatDate(data?.report?.sent_at)}
              </Text>
          </ModalHeader>
          <ModalBody pt={['24px']} px={['50px']} h={'650px'}>
              <ReportPrintPreview ref={chartRef} data={data} isLoading={isLoading} isFetching={isFetching} requestType={data?.report?.request_type} />
              <ReportReview requestType={data?.report?.request_type} data={data} isLoading={isLoading} isFetching={isFetching} />
          </ModalBody>
          <ModalFooter px={[0]}>
              <Box w={'full'}>
                  <Flex justify={['center']} py={['15px']} w={'full'}>
                      <Button 
                        variant={'outline'} 
                        mr={['5px']} 
                        size={['lg']} 
                        isLoading={isLoading || isFetching}
                        onClick={handlePrint}
                      >
                          <FormattedMessage
                              id={'reportDetailsPage.reviewReportModal.footer.button.downloadpdf'}
                              defaultMessage={'Download PDF'}
                          />
                      </Button>
                      <Button 
                        variant={'solid'} 
                        ml={['5px']} 
                        size={['lg']} 
                        onClick={onClose}
                      >
                          <FormattedMessage
                              id={'patientDetailsPage.reviewReportModal.footer.button.close'}
                              defaultMessage={'Close'}
                          />
                      </Button>
                  </Flex>
              </Box>
          </ModalFooter>
      </ModalContent>
    </Modal>
  )    
}

PatientReportReviewModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    reportUid: PropTypes.string.isRequired,
    requestType: PropTypes.string
  }
  
  export { PatientReportReviewModal, PatientReportPdfReviewModal };