import React from 'react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    IconButton,
    Box,
} from '@chakra-ui/react';
import { FiHelpCircle } from 'react-icons/fi'; // Using react-icons/fi for question mark
import PropTypes from 'prop-types';

/**
 * HelpPopover - A component that displays help text in a popover
 * 
 * @param {Object} props
 * @param {Object} props.title - The title message descriptor for FormattedMessage
 * @param {Object} props.description - The description message descriptor for FormattedMessage
 * @param {string} props.placement - Popover placement (optional, default: 'right')
 */
export const HelpPopover = ({ title, description, placement = 'right' }) => {
    return (
        <Popover placement={placement} trigger="hover" closeOnBlur={true}>
            <PopoverTrigger>
                <IconButton
                    aria-label="Help information"
                    icon={<FiHelpCircle />}
                    size="sm"
                    variant="ghost"
                    ml={2}
                    color={'#121719'}
                    isRound
                />
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>
                    <Box fontWeight="semibold">
                        {title}
                    </Box>
                </PopoverHeader>
                <PopoverBody>
                    {description}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

HelpPopover.propTypes = {
    title: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired,
    }).isRequired,
    description: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired,
    }).isRequired,
    placement: PropTypes.string,
};