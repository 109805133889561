/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import {  useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Flex, Box, HStack } from '@chakra-ui/react';
import styled, {css} from 'styled-components'
import cn from 'classnames';
import { toUpper, upperFirst } from 'lodash';
import { URLS } from 'src/_config';

import Alignment from '../_components/Alignment/Alignment';
//import { ChannelService } from '../ChannelIO';
//import { GoogleLogin } from 'react-google-login';

import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { useAuth } from 'src/_libs/auth';
import { getIntlMessages } from 'src/App/_redux/selectors';

import { services } from 'src/App/_redux/services';
import PasswordShowIcon from 'src/_images/icons/PasswordShowIcon';
import PasswordHideIcon from 'src/_images/icons/PasswordHideIcon';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { getFinalFormMetaError, isFinalFormMetaInvalid } from 'src/_libs/forms';

/*ReactDOM.render(
  <GoogleLogin
    clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
    buttonText="Login"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />,
  document.getElementById('googleButton')
);*/

const Page = styled.div`
  height:100vh;
`;

/*const StyledButton = styled.button`
  background: #273238;
  box-shadow: 0px 2px 14px rgba(39, 50, 56, 0.3);
  border-radius: 28px;
  color:white;
  padding: 10px 15px;
  font-weight: 700;
  width: fit-content;
  min-width: 200px;
  margin: 0 auto;
  margin-top: 30px;
`;*/

const StyledForm = styled.form`
  width: 400px;

  background: #F7F9FA;
  border: 1px solid #EDEDED;
  box-shadow: 1px 3px 20px rgba(239, 239, 239, 0.5);
  border-radius: 10px;

  text-align:center;

  display:flex;
  flex-direction: column;
  padding: 20px 50px 40px;
`;

const FormHeader = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-size: 22px;
  margin: 20px 0 0px 0;
  text-transform: capitalize;
`;

const FormSubheader = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-size: 14px;
  margin: 10px 0 10px 0;
`;

const StyledInputLayout = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 0;
`;

const StyledLabel = styled.label`
  margin: 10px 0;
  font-size: 14px;
  width:100%;
  text-align: left;
`;

const ErrorLabel = styled.div`
  visibility:hidden;
  width:100%;
  height:15px;
  margin-top:10px;
  font-size:12px;
  text-align:left;
  ${props => props.addCSS}
`;


const StyledInput = styled.input`
  background: #FFFFFF;
  border: 1px solid #C7D3D9;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 15px;
  width:100%;
  ${props => props.addCSS}
`;


export const StyledInputWithOverlay = styled.div`
  position:relative;
    width:100%;
  > div{
    position:absolute;
    display:flex;
    align-items:center;
    top: 0;
    bottom: 0;
    right: 0;
    height: 43px;
    padding: 15px 0;
    padding-right: 10px;


    &:hover{
      cursor:pointer;
      opacity: 0.8;
    }
  }
`;


const errorCss = css`
  border-color:red !important;
  border-width:2px;
  visibility:visible;
`;


export const PasswordResetPage = () => {
  const { login } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const emailRef = useRef();
  const [uid, setUid] = useState(null)
  const [token, setToken] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)
  const [initValues, setInitValues] = useState({})
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [password2Hidden, setPassword2Hidden] = useState(true);
  const [ sent, setSent ] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const intlMessages = useSelector(getIntlMessages);

  //const [verificationCode, setVerificationCode] = React.useState('');

  const BOX_PROPS = {
    mt:['10px'],
  }

  const requestResetPassword = async (email) => {
    if (email){
      setSent(true)
      // send email to user
      try{
        const user = await services.requestCodeResetPassword({email});
      } catch (err){
        emailRef.current.focus();
        emailRef.current.blur();
        setSent(false);
      }
    } else {
      emailRef.current.focus();
      emailRef.current.blur();
      setSent(false);
    }
  }

  const handleButtonClick = async () => {
    const email = emailRef.current.value;
    setIsButtonDisabled(true);
    try{
      await requestResetPassword(email);
    } catch (err){
      console.log(err);
      setIsButtonDisabled(false);
      return;
    }

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 45000); // 45 seconds
  };

  // handles form submission
  const handleReset = async (submitValues) => {
    let values = submitValues;
    if (values.new_password1 !== values.new_password2){
      return {
        new_password2: 'passwordMatch'
      }
    }
    setIsLoading(true);
    try{
      const user = await services.resetPassword(values);
      history.push(URLS.login.url);
    } catch (err){
      var errType = 'general.error';
      if (err.token !== undefined || err.uid !== undefined){
        errType = 'token';
      }
      return {
        ...err,
        [FORM_ERROR] : errType

      }
    } finally{
      setIsLoading(false);
    }
  }

  // C
  const RequestButton = () => {
    return (
        <Button
          variant={'outline'}
          size={'lg'}
          type="button"
          isLoading={isLoading}
          isDisabled={isButtonDisabled}
          onClick={handleButtonClick}
        >
          {
            isButtonDisabled ? (
              <FormattedMessage
                id={'resetPasswordForm.form.requestDisabled'}
                defaultMessage={'Sent!'}
              />
            ) : (
              <FormattedMessage
                id={'resetPasswordForm.form.request'}
                defaultMessage={'Send'}
              />
            )
          }
        </Button>
    )
  }

  const PasswordShowHideButton = ({passwordHidden, setPasswordHidden}) => {
    return (
      <Box _hover={{cursor: 'pointer'}} onClick={() => setPasswordHidden(old => !old)}>
        { passwordHidden ? <PasswordShowIcon /> : <PasswordHideIcon />  }
      </Box>
    )
  }

  return (
    <Page>
        <Flex justifyContent="center" alignItems="center" pb={['100px']}>
            <Form
                onSubmit={handleReset}
                render = {({
                handleSubmit,
                submitting,
                values,
                submitError
                }) => (
                <StyledForm
                    onSubmit={handleSubmit}
                    >
                    <FormHeader>
                        {intlMessages['resetPasswordForm.form.submit']}
                      </FormHeader>
                      <FormSubheader>
                          {intlMessages['resetPasswordForm.form.header']}
                      </FormSubheader>
                    <Field name="email" parse={v => v}>
                        {({ input, meta}) => (
                          <HStack align={'flex-end'} w={'full'} spacing={[3]}>
                            <InputComponent
                              label={toUpper(intlMessages['resetPasswordForm.form.email.label'])}
                              placeholder={upperFirst(intlMessages['resetPasswordForm.form.email.label'])}
                              {...input}
                              onChange={(e) => {
                                input.onChange(e);
                                emailRef.current = e.target;
                              }}
                              isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                              error={getFinalFormMetaError(meta)}
                            />
                            <RequestButton />
                          </HStack>
                        )}
                    </Field>
                    <Field name="code" parse={v => v}>
                        {({ input, meta}) => (
                          <InputComponent
                            boxProps={BOX_PROPS}
                            label={toUpper(intlMessages['resetPasswordForm.form.code.label'])}
                            placeholder={upperFirst(intlMessages['resetPasswordForm.form.code.label'])}
                            {...input}
                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                            error={getFinalFormMetaError(meta)}
                          />
                        )}
                    </Field>
                    <Field name="new_password1" parse={v => v}>
                        {({ input, meta}) => (
                          <InputComponent
                            boxProps={BOX_PROPS}
                            label={toUpper(intlMessages['resetPasswordForm.form.password.label'])}
                            placeholder={upperFirst(intlMessages['resetPasswordForm.form.password.label'])}
                            {...input}
                            type={passwordHidden ? 'password' : 'text'}
                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                            error={getFinalFormMetaError(meta)}
                            inputRightButton={<PasswordShowHideButton passwordHidden={passwordHidden} setPasswordHidden={setPasswordHidden} />}
                          />
                        )}
                    </Field>
                    <Field name="new_password2" parse={v => v}>
                        {({ input, meta}) => (
                          <InputComponent
                            boxProps={BOX_PROPS}
                            label={toUpper(intlMessages['resetPasswordForm.form.password2.label'])}
                            placeholder={upperFirst(intlMessages['resetPasswordForm.form.password2.label'])}
                            {...input}
                            type={password2Hidden ? 'password' : 'text'}
                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                            error={getFinalFormMetaError(meta)}
                            inputRightButton={<PasswordShowHideButton passwordHidden={password2Hidden} setPasswordHidden={setPassword2Hidden} />}
                          />
                        )}
                    </Field>
                    <Button
                        mt={['20px']}
                        variant={'solid'}
                        size={'lg'}
                        type="submit"
                        isLoading={submitting}
                        isDisabled={!(values?.email && values?.new_password1 && values?.new_password2 )}
                    >
                        <FormattedMessage
                        id={'resetPasswordForm.form.submit'}
                        defaultMessage={'Reset Password'}
                        />
                    </Button>
                </StyledForm>
                )}
            /> 
            </Flex>
        </Page>
      )
}
