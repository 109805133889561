import React, {useState, useEffect, useRef, useCallback} from 'react';
import ReactDOMServer from 'react-dom/server';
import { motion } from "framer-motion";
import { useSelector  } from 'react-redux';
import styled from 'styled-components';
import { TOOTH_NUMBERING_SYSTEM } from 'src/_config';
import { MdFileDownload, MdRefresh, MdErrorOutline } from 'react-icons/md'
import { Flex, Center, Box, Text, Button, Spinner, Image, 
    Modal, ModalOverlay, ModalHeader, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react';

import { localeFormatDate } from 'src/_helpers';
import InnerImageZoom from 'react-inner-image-zoom'
import ReactPlayer from 'react-player/lazy'
import { Carousel } from 'react-responsive-carousel';
import { RecordUpload } from '../_helpers/models';

import { StyledVideoView } from "src/Reports/components/styles";
import { PaperclipIcon } from 'src/_images/icons/PaperclipIcon';
// components
import { RecordUploadMedia } from '../_components/RecordUploadMedia';

import { FormattedMessage, useIntl } from 'react-intl';

// actions

// selectors
import { getIntlMessages } from 'src/App/_redux/selectors';
import { usePatientRecord } from './api/getPatientRecord';

// styles
import { StyledRecordUploadMedia, StyledLoadingMedia } from '../_components/RecordUploadMedia/styles';
import { consoleLog } from '../_helpers/consoleLog';
import { useAppConfig } from 'src/App/state/appConfig';

const CURRENT_TOOTH_NUMBERING_SYSTEM = 'palmer';

function useCurrentRef() {
    const [ image, setImage ] = useState(null);
    const ref = useCallback(node => {
      if (node !== null) {
        setImage(node);
      }
    }, [])
    return [image, ref];
  }
/**
 * Copied partially from src/Reports/components/MediaColumn.jsx
 * @param {*} param0 
 * @returns 
 */
const VideoView = ({ srcUrl, description, onError}) => {
    //console.log("hasDetections ", hasDetections)
    //const { data: detectionData, isLoading: detectionsIsLoading } = useUploadDetections({recordUploadUid: uid});
    // data/fps
    //const { isDetectionShowing, isDetectionLabelShowing, setIsDetectionLabelShowing, setCurrentVideoFrameData } = useMediaStore();

    const videoPlayerRef = useRef();
    //const currentVideoFrameList = useSelector(getCurrentVideoFrames);
    //const videoFrames = useSelector(getVideoFrames);

    return(
      <StyledVideoView>
        <div className={'videoPlayerContainer'}>
          { (description && description.length > 0) && 
            <Flex align={'center'}
              position={'absolute'} width={'100%'} top={0} left={0} right={0} 
              padding={'10px 15px'} 
            >
                <Text color={'white'}>{description}</Text>
            </Flex>
          }
          <ReactPlayer
            ref={videoPlayerRef}
            url={srcUrl}
            muted={true}
            playbackRate={0.8}
            width={'100%'}
            height={'auto'}
            controls={true} 
            onError={onError}
          />
        </div>
      </StyledVideoView>
    )
  }

const PhotoView = ({srcUrl, description, onError}) => {
    const [image, ref] = useCurrentRef();
    // eslint-disable-next-line no-unused-vars
    const [imageIndex, setImageIndex ] = useState(null);
    
    useEffect(()=>{
      if (image !== null){
        setImageIndex(ReactDOMServer.renderToString(image.current))
        //dispatch(actions.updateStateObject("currentImageComponent", {[currentImageIndex]:  ReactDOMServer.renderToString(image.current)}))
      }
    }, [image]);

    useEffect(()=>{
      return () => {};
    }, [])

    return(
      <Box ref={ref} bg={['#F7F9FA']}>
        { description && 
          <Flex align={'center'}
            position={'absolute'} width={'100%'} top={0} left={0} right={0} 
            bg={'rgba(0,0,0,0.2)'} padding={'10px 15px'} 
          >
              <Text color={'white'}>{description}</Text>
          </Flex>
        }
        <img 
          src={srcUrl}
          style={{display:'none', width:640, height:0}}
          onError={onError}
          width={0}
        />
        <InnerImageZoom
          zoomScale={1.5}
          zoomSrc={srcUrl}
          src={srcUrl}
        />
      </Box>
    )
  }

const isVideo = (fileUrl, mimeType) => {
    if (mimeType && mimeType.includes("video")){
      return true;
    } else if (fileUrl && (fileUrl.includes(".mp4") || fileUrl.includes(".mkv") || fileUrl.includes(".m4v") || fileUrl.includes(".webm") || fileUrl.includes(".mov")) ){
      return true;
    } else {
      return false;
    }
}

const MediaView = ({currentUpload}) => {
    const intlMessages = useSelector(getIntlMessages);
      
    const [ error, setError] = useState(null);
    const [ mimeType, setMimeType ] = useState(null);
  
    useEffect(() => {
      if (currentUpload) {
        if (currentUpload.getUploadHead()){
          fetch(currentUpload.getUploadHead(), {method: 'HEAD'})
            .then( response => {
              if (response.status === 403){
                setError(true);
                return null;
              }
              let contenttype = response.headers.get("content-type");
              setMimeType(contenttype)
            }).catch(() => {
              setError(true);
            })
        } else if (currentUpload.getUpload()) {
          setMimeType("image/*");
        } else {
          //setError(true);
          setMimeType("video/*");
        }
      }
    }, [currentUpload])
  
    useEffect(()=>{
      return () => {};
    }, [])
  
    const refetchUpload = () => {
      //console.log("refetchUpload")
    }
  
    const onImageError = () => {
      consoleLog("MediaSection image error")
      setMimeType("video/*");
    }
  
    const onVideoError = (...props) => {
      consoleLog("MediaSection video error: ", props)
      //setError(true);
    }
  
    let status = "";
    if (currentUpload && currentUpload.getRegion() > -1){
      status = intlMessages[`requestDetailPage.upload.region.${currentUpload.getRegion()}.label`]
      status += ": "
      status += TOOTH_NUMBERING_SYSTEM[CURRENT_TOOTH_NUMBERING_SYSTEM][intlMessages[`requestDetailPage.upload.region.${currentUpload.getRegion()}.teethrange.start`]]
      status += " -> "
      status += TOOTH_NUMBERING_SYSTEM[CURRENT_TOOTH_NUMBERING_SYSTEM][intlMessages[`requestDetailPage.upload.region.${currentUpload.getRegion()}.teethrange.end`]]
    }
  
    if (currentUpload == null){
      return (
        <Flex minHeight={'480px'} borderRadius={'2px'} bg={'#F7F9FA'}  minWidth={'640px'} align={'center'} justify={'center'}>
        </Flex>
      )
    }
  
    if (error){
      return(
        <Flex direction={'column'}  borderRadius={'2px'} bg={'#F7F9FA'}  minHeight={'480px'} minWidth={'640px'} align={'center'} justify={'center'}>
            <MdErrorOutline size={26} fill={'#F93D5C'} />
            <Text fontSize={14} mt={2}>{'Error fetching asset'}</Text>
            <Text fontSize={12} color={'none.500'}>{'Click the Refresh button to refetch the asset'}</Text>
            <Button 
              leftIcon={<MdRefresh size={16} />} 
              mt={4}
              size={'sm'}
              fontSize={14} 
              variant="solid" 
              colorScheme={'bdBlue'}
              onClick={refetchUpload}
            >
              {'Refresh'}
            </Button>
        </Flex>
      )
    }

    if (isVideo(currentUpload.getUpload(), mimeType)){
      return (
          <VideoView 
            onError={onVideoError} 
            upload={currentUpload}
            hasDetections={currentUpload !== undefined ? currentUpload?.upload?.has_detections : null} 
            description={status} 
            srcUrl={currentUpload.getUpload()} 
            uid={currentUpload.getId()} 
            id={currentUpload.getIntId()}
          />
      )
    } else if(mimeType && mimeType.includes("image")){
      return (
          <PhotoView 
            onError={onImageError} 
            description={status} 
            srcUrl={currentUpload.getUpload()} 
          />
      )
    } else if(mimeType){
      return (
        <Flex direction={'column'} minHeight={'480px'} borderRadius={'2px'} bg={'#F7F9FA'}  minWidth={'640px'} align={'center'} justify={'center'}>
          <PaperclipIcon width={22} />
          <Text fontSize={14} mt={2}>{'No Preview Available'}</Text>
          <Text fontSize={12} color={'none.500'}>{currentUpload?.upload?.filename}</Text>
          <a style={{'text-decoration': 'none'}} download={currentUpload?.upload?.filename} href={currentUpload.getUpload()} title="downloadFIle">
            <Button 
              leftIcon={<MdFileDownload size={16} />} 
              mt={4}
              size={'sm'}
              fontSize={14} 
              variant="solid" 
              colorScheme={'bdBlue'}
            >
              {'Download'}
            </Button>
          </a>
        </Flex>
      )
    } else {
      return(
        <Flex minHeight={'480px'} borderRadius={'2px'} bg={'#F7F9FA'}  minWidth={'640px'} align={'center'} justify={'center'}> 
            <Spinner 
            size='xl'
            thickness='4px'
            speed='0.65s' 
            color='#44C5A6' 
            />
        </Flex>
      )
    }
  }


/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
//const swipeConfidenceThreshold = 10000;
//const swipePower = (offset, velocity) => {
//  return Math.abs(offset) * velocity;
//};


const PreviewImage = ({upload}) => {
  //const dispatch = useDispatch();
  if (upload){
    return (
      <RecordUploadMedia
        key={'RecordUploadMediaL' + upload.id}
        upload={new RecordUpload(upload)}
        imgWidth={640}
        imgHeight={480}
        zoom={false}
        imgBorderRadius={5}
        play={true}
      />
    )
  } else {
  return (
    <div
      style={{
        height: '100%',
        width: '100%'
      }}
      >
    </div>
  )
  }
}

export const StyledMediaPreview = styled(motion.div)`
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  img { border-radius: 4px; }
  video { border-radius: 4px; }

  ${StyledRecordUploadMedia}, ${StyledLoadingMedia}{
    background-color: transparent;
  }
`;

export const MediaPreview = ({upload}) => {
  return (
    <StyledMediaPreview>
      <PreviewImage upload={upload}/>
    </StyledMediaPreview>
  )
}

const Thumbnail = ({upload}) => {    
    const thumbnailWidth = 180
    const thumbnailHeight = 136
    const intlMessages = useSelector(getIntlMessages);

    let status = "";
    if (upload && upload.getRegion() > -1){
        status = intlMessages[`requestDetailPage.upload.region.${upload.getRegion()}.label`]
        //status += ": "
        //status += TOOTH_NUMBERING_SYSTEM[CURRENT_TOOTH_NUMBERING_SYSTEM][intlMessages[`requestDetailPage.upload.region.${upload.getRegion()}.teethrange.start`]]
        //status += " -> "
        //status += TOOTH_NUMBERING_SYSTEM[CURRENT_TOOTH_NUMBERING_SYSTEM][intlMessages[`requestDetailPage.upload.region.${upload.getRegion()}.teethrange.end`]]
    }
    if (!upload.isVideo()){
      return (
        <Flex direction={'column'}>
            <Image 
                src={upload.getUpload()}
                w={[`${thumbnailWidth}px`]}
                h={[`${thumbnailHeight}px`]}
                alt={'upload'}
            />
            <Text textAlign={'center'} fontSize={'10px'} wrap={'wrap'}>
                {status}
            </Text>
        </Flex>
      )
    } else {
        return (
          <Flex direction={'column'}>
            <video 
              src={upload.getUpload()}
              width={[`${thumbnailWidth}px`]}
              height={[`${thumbnailHeight}px`]}
              alt={'upload'}
            >
              <source src={upload.getUpload()} />
            </video>
            <Text textAlign={'center'} fontSize={'10px'} wrap={'wrap'}>
                {status}
            </Text>
          </Flex>
        )
      }
  }
  
const renderThumbnails = (uploads) => {
    return (uploads || []).map(upload => <Thumbnail key={upload.id} upload={new RecordUpload(upload)} />)
  }  

export const PatientRecordDetail = ({record}) => {
    //const [[uploadIndex, direction], setPage] = useState([0, 0]);
    const [ imageIndex, setImageIndex ] = useState(0);
    //const recordIndex = wrap(0, records.length, page);

    const handleClick = (index) => {
        setImageIndex(index);
    }


    const sorted_record_list = record?.patientrecord_uploads?.sort((a, b) => {
      // Handle undefined or null values
      let regionA = a?.upload?.extra_data?.region;
      let regionB = b?.upload?.extra_data?.region;
      
      // Convert string numbers to actual numbers
      if (typeof regionA === 'string' && !isNaN(Number(regionA))) {
        regionA = Number(regionA);
      }
      
      if (typeof regionB === 'string' && !isNaN(Number(regionB))) {
        regionB = Number(regionB);
      }
      
      // If both are numbers (including converted strings), do numeric comparison
      if (typeof regionA === 'number' && typeof regionB === 'number') {
        return regionA - regionB;
      }
      
      // Convert to strings for string comparison or if they're different types
      return String(regionA || '').localeCompare(String(regionB || ''));
    });


    return (
        <Carousel
            statusFormatter={() => status}
            selectedItem={imageIndex}
            showThumbs={true}
            showArrows={false}
            showIndicators={false}
            onChange={handleClick}
            thumbWidth={160}
            renderThumbs={() => renderThumbnails(sorted_record_list)}
        >
        { (sorted_record_list || []).map((upload,) =>
            <MediaView key={upload.unique_id} currentUpload={new RecordUpload(upload)}/>
        )}
    </Carousel>
    )
}

export const PatientSingleRecordModal = ({isOpen, onClose, recordUid}) => {
    const clinic = useAppConfig(state => state.clinic?.id)
    const { data , isLoading, isFetching } = usePatientRecord({recordUid, clinic});
    const { locale } = useIntl();

    useEffect(() => {
        return () => {};
    }, [])

    return(
        <Modal isOpen={isOpen} onClose={onClose}  isCentered >
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent minW={'780px'} bg="#f7f9fa">
                <ModalHeader >
                    <Center>
                        <FormattedMessage 
                          id={'patientSingleRecordModal.title'}
                          defaultMessage={'Patient Record'}
                        />
                        {data?.record?.created_at && `: ${localeFormatDate(data?.record?.created_at, locale, "ll")}`}
                    </Center>
                </ModalHeader>

                <ModalCloseButton />
                <ModalBody pb={'10px'}>
                    {
                        isLoading || isFetching ? (
                            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                                <Spinner 
                                    size='xl'
                                    thickness='4px'
                                    speed='0.65s' 
                                    color='#44C5A6'
                                />
                            </Flex>
                        ) : (
                            <PatientRecordDetail
                                record={data?.record || {}}
                            />
                        
                        )
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
