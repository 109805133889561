import React from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners'

import { cloneDeep } from "lodash";
import {
    Box, Flex, SimpleGrid, Button, Spinner, 
    Input, FormControl, FormLabel, FormErrorMessage, VStack, InputGroup, InputRightElement,
} from '@chakra-ui/react';
import { CheckboxComponent } from 'src/_components/Forms/CheckBoxComponent';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from 'src/_libs/forms';
import { useSelectOptions } from '../api/getSelectOptions';
import { convertToAPIValues, generatePassword } from 'src/_libs/forms';
import { editPatientUserDetails, createPatientUser } from '../api/services';
import { ClinicDentistSearch } from './DentistSearch';
import { usePatientUserDetails, updatePatientUserDetails } from '../api/getPatientUserDetails';

import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';


const PasswordComponent = ({ label, placeholder, name, type="text", value, isInvalid, error, onChange, onGenerate }) => {
    const handleClick = () => onGenerate(generatePassword());
  
    return (
        <VStack>
            <FormControl id={name} isInvalid={isInvalid}>
                <FormLabel>{label}</FormLabel>
                <InputGroup>
                    <Input
                        placeholder={placeholder} type={type} value={value} onChange={onChange} isInvalid={isInvalid}
                    />
                    <InputRightElement width='80px' mr={'10px'}>
                        <Button h='35px' variant={'ghost'} onClick={handleClick}>
                            <FormattedMessage
                                id={'adminPage.form.field.password.generate'}
                                defaultMessage={'Generate'}
                            />
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{error ? error  : ""}</FormErrorMessage>
            </FormControl>
        </VStack>
     
    )
  }

const WhenFieldChanges = ({ field, becomes, set, to }) => (
    <Field name={set} subscription={{}}>
      {(
        // No subscription. We only use Field to get to the change function
        { input: { onChange } }
      ) => (
        <FormSpy subscription={{}}>
          {() => (
            <OnChange name={field}>
              {value => {
                if (value === becomes) {
                  onChange(to);
                }
              }}
            </OnChange>
          )}
        </FormSpy>
      )}
    </Field>
  );


const WhenFieldAnyChanges = ({ field, set, to }) => (
    <Field name={set} subscription={{}}>
      {(
        // No subscription. We only use Field to get to the change function
        { input: { onChange } }
      ) => (
        <FormSpy subscription={{}}>
          {() => (
            <OnChange name={field}>
              {() => onChange(to)}
            </OnChange>
          )}
        </FormSpy>
      )}
    </Field>
  );

/**
 * Creates a new user, dentist, and assigns them to a clinic
 * @param {*} param0 
 * @returns 
 */
export const EditPatientUserForm = ({
    userId,
    onSuccess,
    onClose,
}) => {
    const { data, isLoading, isFetching, isError, error } = usePatientUserDetails({userId})
    const { data: countriesData, isLoading: countriesIsLoading, isFetching: countriesIsFetching } = useSelectOptions({'option': 'countries'});
    const { data : gendersData, isLoading : gendersIsLoading, isFetching : gendersIsFetching } = useSelectOptions({'option': 'genders'});
    const { formatMessage } = useIntl();
   
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        try{
            const result = await editPatientUserDetails(userId, convertedValues);
            updatePatientUserDetails(userId, result);
            onSuccess && onSuccess(result);
            onClose();
            return null;
        } catch (err) {
            console.log("error ", err)
            return {
              ...err
            };
        }
    }

    if (isError){
        return (
            <Box>
                <ErrorNotFound error={error} size={"md"} buttonType={'refresh'} />
            </Box>
        )
    }

    if (isLoading || isFetching){
        return ( 
            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
            </Flex>
        )
    } else {
        return (
            <>    
                <SimpleGrid columns={[1]} spacing={colSpacing}  mt={rowSpacing}>
                    <VStack>
                        <FormControl id={'email'}>
                            <FormLabel>
                                <FormattedMessage
                                    id="adminPage.userForm.field.patientUserEmail.label"
                                    defaultMessage="User Email"
                                />
                            </FormLabel>
                            <Input type={"text"} isDisabled={true} value={data?.email}/>
                            <FormErrorMessage>{''}</FormErrorMessage>
                        </FormControl>
                    </VStack>
                </SimpleGrid>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={{
                        ...data,
                        country: {
                            value:  data?.country,
                            label: data?.country
                        }
                    }}
                    mutators={{ 
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        }
                    }}
                    render = {
                        ({
                            handleSubmit,
                            submitting,
                            values,
                        }) => {
                            return (
                                <Box as={'form'} onSubmit={handleSubmit}>
                                    <WhenFieldChanges
                                        field="is_clinic_user"
                                        becomes={true}
                                        set="is_dental_type"
                                        to={true}
                                    />
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="country" validate={required}>
                                            {({input, meta}) => (
                                                <SelectOptionField 
                                                    isDisabled={true}
                                                    isLoading={countriesIsLoading || countriesIsFetching}
                                                    label={formatMessage({id: 'adminPage.form.field.country.label', defaultMessage: 'Country'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.country.label', defaultMessage: 'Country'})}
                                                    input={input}
                                                    selectOptions={countriesData?.options}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="first_name" validate={required}>
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                                    placeholder={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="last_name">
                                            {({input, meta}) => (
                                                <InputComponent 
                                                    label={formatMessage({id: 'adminPage.userForm.field.last_name.label', defaultMessage: 'Last Name (Optional)'})}
                                                    placeholder={formatMessage({id: 'adminPage.userForm.field.last_name.placeholder', defaultMessage: 'Last Name'})}
                                                    {...input}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="date_of_birth">
                                            {({input, meta}) => 
                                                {
                                                    console.log("date of birth ", meta)
                                                    console.log("date of birth submitError ", meta.submitError)
                                                    console.log("date of birth error ", meta.error)
                                                    return (
                                                        <InputComponent 
                                                            label={formatMessage({id: 'adminPage.userForm.field.date_of_birth.label', defaultMessage: 'Date of Birth (Optional)'})}
                                                            placeholder={formatMessage({id: 'adminPage.userForm.field.date_of_birth.placeholder', defaultMessage: 'Date of Birth'})}
                                                            {...input}
                                                            type="date"
                                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                            error={getFinalFormMetaError(meta)}
                                                        />
                                                    )
                                                }
                                            
                                            }
                                        </Field>
                                        <Field name="gender">
                                            {({input, meta}) => (
                                                <SelectOptionField 
                                                    isLoading={gendersIsFetching || gendersIsLoading}
                                                    isMultiple={false}
                                                    label={formatMessage({id: 'adminPage.userForm.field.gender.label', defaultMessage: 'Gender (Optional)'})}
                                                    placeholder={formatMessage({id: 'adminPage.userForm.field.gender.placeholder', defaultMessage: 'Gender'})}
                                                    input={input}
                                                    selectOptions={gendersData?.options}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="is_organization_user">
                                            {({input, meta}) => (
                                                <CheckboxComponent
                                                    isDisabled={true}
                                                    label={formatMessage({id: 'adminPage.form.field.patient.is_organization_user.label', defaultMessage: 'For Organization?'})}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="is_clinic_user">
                                            {({input, meta}) => (
                                                <CheckboxComponent
                                                    isDisabled={true}
                                                    label={formatMessage({id: 'adminPage.form.field.patient.is_clinic_user.label', defaultMessage: 'For Clinic?'})}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                        <Field name="is_dental_type">
                                            {({input, meta}) => (
                                                <CheckboxComponent
                                                    label={formatMessage({id: 'adminPage.form.field.patient.is_dental_type.label', defaultMessage: 'Forward Requests to Clinic Automatically?'})}
                                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                    error={getFinalFormMetaError(meta)}
                                                    {...input}
                                                    isDisabled={values.is_clinic_user == true}
                                                />
                                            )}
                                        </Field>
                                    </SimpleGrid>
                                    <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                        <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                            <FormattedMessage 
                                                id="adminPage.form.cancel.button.label"
                                                defaultMessage="Cancel"
                                            />
                                        </Button>
                                        <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                            <FormattedMessage 
                                                id="adminPage.form.submit.button.label"
                                                defaultMessage="Submit"
                                            />
                                        </Button>
                                    </SimpleGrid>
                                </Box>
                            )
                        }
                    }
                />
            </>
        )
    }

}

export const PatientUserForm = ({
    onSuccess,
    onClose,
    initValues = {is_organization_user: true},
  }) => {
    const { data, isLoading, isFetching } = useSelectOptions({'option': 'countries'});
    const { data : gendersData, isLoading : gendersIsLoading, isFetching : gendersIsFetching } = useSelectOptions({'option': 'genders'});
    const { formatMessage } = useIntl();
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        try{
            const result = await createPatientUser(convertedValues);
            onSuccess && onSuccess(result);
            onClose();
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initValues}
            mutators={{ 
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              }
            }}
            render = {
                ({
                    handleSubmit,
                    submitting,
                    values,
                    form: { mutators: { setValue } } // pass custom mutator here
                }) => {
                    console.log("values country ", values.country)
                    return (
                        <Box as={'form'} onSubmit={handleSubmit}>
                            <WhenFieldChanges
                                field="is_clinic_user"
                                becomes={true}
                                set="is_dental_type"
                                to={true}
                            />
                            <WhenFieldAnyChanges
                                field="country"
                                set="clinic_dentist"
                                to={null}
                            />
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="country" validate={required}>
                                    {({input, meta}) => (
                                        <SelectOptionField 
                                            isLoading={isFetching || isLoading}
                                            label={formatMessage({id: 'adminPage.form.field.country.label', defaultMessage: 'Country'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.country.label', defaultMessage: 'Country'})}
                                            input={input}
                                            selectOptions={data?.options}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={rowSpacing}>
                                <Field name="email" validate={required}>
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.login_email.label', defaultMessage: 'Login Email'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.login_email.label', defaultMessage: 'Login Email'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                                <Field name="password" validate={required}>
                                    {({input, meta}) => (
                                        <PasswordComponent 
                                            label={formatMessage({id: 'adminPage.form.field.password.label', defaultMessage: 'Password'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.password.label', defaultMessage: 'Password'})}
                                            {...input}
                                            onGenerate={(value) => setValue('password', value)}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="first_name" validate={required}>
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                                <Field name="last_name">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.userForm.field.last_name.label', defaultMessage: 'Last Name (Optional)'})}
                                            placeholder={formatMessage({id: 'adminPage.userForm.field.last_name.placeholder', defaultMessage: 'Last Name'})}
                                            {...input}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="date_of_birth">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.userForm.field.date_of_birth.label', defaultMessage: 'Date of Birth (Optional)'})}
                                            placeholder={formatMessage({id: 'adminPage.userForm.field.date_of_birth.placeholder', defaultMessage: 'Date of Birth'})}
                                            {...input}
                                            type="date"
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                                <Field name="gender">
                                    {({input, meta}) => (
                                        <SelectOptionField 
                                            isLoading={gendersIsFetching || gendersIsLoading}
                                            isMultiple={false}
                                            label={formatMessage({id: 'adminPage.userForm.field.gender.label', defaultMessage: 'Gender (Optional)'})}
                                            placeholder={formatMessage({id: 'adminPage.userForm.field.gender.placeholder', defaultMessage: 'Gender'})}
                                            input={input}
                                            selectOptions={gendersData?.options}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="clinic_dentist">
                                    {({input, meta}) => (
                                        <ClinicDentistSearch
                                            input={input}
                                            meta={meta}
                                            country={values.country?.code}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                            label={formatMessage({id: 'adminPage.userForm.field.request_dentist.label', defaultMessage: 'Assigned Dentist (Optional)'})}
                                            helpDescription={"Assign a default dentist to this patient otherwise the country's default dentist will receive report requests."}
                                            placeholder={formatMessage({id: 'adminPage.userForm.field.accountrole.request_dentist.placeholder', defaultMessage: 'Search for a dentist'})}
                                            onSelect={(val) => {
                                                input.onChange(val);
                                            }}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="is_organization_user">
                                    {({input, meta}) => (
                                        <Flex justify={'flex-start'}>
                                            <CheckboxComponent
                                                label={formatMessage({id: 'adminPage.form.field.patient.is_organization_user.label', defaultMessage: 'For Organization?'})}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                helpDescription={'For organizations such as school or orphanages'}
                                                {...input}
                                            />
                                        </Flex>

                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="is_clinic_user">
                                    {({input, meta}) => (
                                        <CheckboxComponent
                                            label={formatMessage({id: 'adminPage.form.field.patient.is_clinic_user.label', defaultMessage: 'For Clinic?'})}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                            helpDescription={'For clinics who only scan for our patients. '}
                                            {...input}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="is_dental_type">
                                    {({input, meta}) => (
                                        <CheckboxComponent
                                            label={formatMessage({id: 'adminPage.form.field.patient.is_dental_type.label', defaultMessage: 'Forward Requests to Clinic Automatically?'})}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                            helpDescription={'For patients who will send requests to clinics. Probably should be set as true (is_dental_type)'}
                                            {...input}
                                            isDisabled={values.is_clinic_user == true}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="is_demo_user">
                                    {({input, meta}) => (
                                        <CheckboxComponent
                                            label={formatMessage({id: 'adminPage.form.field.patient.is_demo_user.label', defaultMessage: 'Demo User?'})}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                            helpDescription={'For creating demo patient user'}
                                            {...input}
                                            isDisabled={values.is_clinic_user == true}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.cancel.button.label"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.submit.button.label"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </SimpleGrid>
                        </Box>
                    )
                }
            }
        />
    )
}