/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import { Form, Field } from 'react-final-form';
import { 
    Flex, Box, Text, HStack, VStack, FormLabel, SimpleGrid, Select, Button, IconButton,
    Editable, EditableInput, EditablePreview, Checkbox, useToast,
    TableContainer, Table, Thead, Tbody, Tr, Th, Td  } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, flexRender,
    getFilteredRowModel,
    getPaginationRowModel, } from '@tanstack/react-table'

import PaginationPrevious from 'src/_images/icons/PaginationPrevious';
import PaginationNext from 'src/_images/icons/PaginationNext';

import { useCSVReader } from 'react-papaparse';
import { FormattedMessage, useIntl } from 'react-intl';

import { isFinalFormMetaInvalid, getFinalFormMetaError, convertToAPIValues } from 'src/_libs/forms';
import { UserSearch } from './UserSearch';
import { createBulkUserProfiles } from '../api/services';
//import { useGenerateLLM } from 'src/LLM/api/checkCSVUserProfileHeaders';

// create component where admin user can import csv files to add user profiles for a user
const Pagination = ({canPreviousPage, canNextPage, previousPage, nextPage, setPageIndex, pageIndex, total, isLoading}) => {
    return (
        <HStack spacing={3} >
            <IconButton 
                isLoading={isLoading}
                icon={<PaginationPrevious />} 
                isDisabled={!canPreviousPage}
                onClick={previousPage}
                borderRadius={'5px'}
            />
            <Select 
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) : 0
                    setPageIndex(page)
                }}
                isDisabled={total < 2}
            >
                {Array.from({length: total}, (_, i) => (
                    <option key={i} value={i} selected={pageIndex == i}>
                        {i + 1}
                    </option>
                ))}
            </Select>
            <IconButton 
                isLoading={isLoading}
                icon={<PaginationNext />} 
                isDisabled={!canNextPage}
                borderRadius={'5px'}
                onClick={nextPage}
            />
        </HStack>
    
    )
}

const isCellErrorAttrs = (cell, row) => {
    const issues = row.original.issues || [];
    const currentCellId = cell.column.id;
    // if cellId is in issues, return true
    if (issues.includes(currentCellId)){
        return {border  : '1px solid red'}
    } else {
        return {}
    }
}

function useSkipper() {
    const shouldSkipRef = React.useRef(true)
    const shouldSkip = shouldSkipRef.current
  
    // Wrap a function with this to skip a pagination reset temporarily
    const skip = React.useCallback(() => {
      shouldSkipRef.current = false
    }, [])
  
    React.useEffect(() => {
      shouldSkipRef.current = true
    })
  
    return [shouldSkip, skip]
  }

const PageTable = ({headers=[], data=null, isLoading, handleApiSubmit, isSubmitting, clearData}) => {
    const PAGE_SIZE = 200;
    const columns = React.useMemo(
        () => headers,
        [headers]
    )
    const [ tableData, setTableData ] = useState(data || []);

    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()

    const table = useReactTable({
        data: tableData,
        columns,
        initialState: {
            pagination: {
                pageSize: PAGE_SIZE
            }
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugTable: true,
        autoResetPageIndex,
        meta: {
            deleteRow: (rowIndex) => {
                skipAutoResetPageIndex()
                console.log("erase rowIndex is", rowIndex)
                setTableData(old =>
                    old.filter((_, index) => index !== rowIndex)
                )
            },
            updateData: (rowIndex, columnId, value) => {
                skipAutoResetPageIndex()
                // if column id is one of 'first_name', 'middle_name', 'last_name'
                // update the name field with the combination of new values
                // and remove 'name' from the errors field in row
                if (columnId == 'first_name' || columnId == 'middle_name' || columnId == 'last_name'){
                    setTableData(old =>
                        old.map((row, index) => {
                            if (index === rowIndex) {
                                const newRow = {
                                    ...old[rowIndex] || {},
                                    [columnId]: value,
                                    name: `${old[rowIndex].first_name} ${old[rowIndex].middle_name} ${old[rowIndex].last_name}`,
                                    issues: (old[rowIndex].issues || []).filter((issue) => issue != 'name')
                                }
                                return newRow
                            }
                            return row
                        })
                    )
                } else if (columnId == 'errors'){
                    // if column id is 'errors', and value is true, then remove the issues from the row
                    if (value){
                        setTableData(old =>
                            old.map((row, index) => {
                                if (index === rowIndex) {
                                    return {
                                        ...(old[rowIndex] || {}),
                                        issues: []
                                    }
                                }
                                return row
                            })
                        )
                    }
                } else {
                    setTableData(old =>
                      old.map((row, index) => {
                        if (index === rowIndex) {
                          return {
                            ...(old[rowIndex] || {}),
                            [columnId]: value,
                            issues: (old[rowIndex].issues || []).filter((issue) => issue != columnId)
                          }
                        }
                        return row
                      })
                    )
                }
            }
        }
    })  

    const handleDeleteRow = (rowIndex) => {
      const updatedCsvData = tableData.filter((_, index) => index !== rowIndex ); // +1 to account for the header row
      setTableData(updatedCsvData);
    };

    return (
        <>
        <HStack justify={'flex-end'}>
            {
                tableData.length > 0 ? (
                    <Button
                        variant={'deleteOutline'}
                        onClick={clearData}
                        
                    >
                        <FormattedMessage
                            id={'adminPage.form.importprofiles.clearData'}
                            defaultMessage={'Clear Data'}
                        />
                    </Button>
                ) : (
                    <></>
                )
            }
            <Button 
                onClick={() => handleApiSubmit(tableData)} 
                variant={'outline'} 
                isLoading={isSubmitting} 
                isDisabled={!tableData.length || isLoading}
            >
                <FormattedMessage
                    id={'adminPage.form.importprofiles.submit'}
                    defaultMessage={'Upload Profiles'} 
                />
                {tableData.length ? ` (${tableData.length})` : ''}
            </Button>
        </HStack>
        <TableContainer>
            <Table mt={'20px'}>
                <Thead>
                    {(table.getHeaderGroups() || []).map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            <Th></Th>
                            {headerGroup.headers.map(header => (
                                <Th key={header.id} colSpan={header.colSpan}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row, rowIndex) => {
                        return (
                        <Tr key={row.id}  _hover={{cursor: 'pointer', bg: '#F7F7F7'}}>
                            <Td>
                              {rowIndex + 1}
                            </Td>
                            {row.getVisibleCells().map(cell => {
                                const previewColor = (cell.getValue()) ? {} : {color: 'gray.300'} 
                                if (cell.column.id == 'issues'){
                                    return (
                                        <Td key={cell.id}>
                                            <Flex justify={'center'} align={'center'}>
                                                <Checkbox 
                                                    isChecked={(row.original?.issues || []).length == 0} 
                                                    isDisabled={(row.original?.issues || []).length == 0}
                                                    onChange={(e) => {e.target.isDisabled = true;table.options.meta?.updateData(row.index, 'errors', true)}}
                                                />
                                            </Flex>
                                        </Td>
                                    )
                                } else {
                                    return (
                                        <Td key={cell.id} {...isCellErrorAttrs(cell, row)}>
                                        
                                            <Editable 
                                                onSubmit={(value) => table.options.meta?.updateData(row.index, cell.column.id, value)} 
                                                defaultValue={cell.getValue()}
                                                minW={'40px'}
                                                placeholder={cell.column.columnDef.header}
                                                {...previewColor}
                                            >
                                                <EditableInput
                                                    variant={'unstyled'}
                                                    marginLeft={'0'}
                                                />
                                                <EditablePreview 
                                                    variant={'unstyled'}
                                                    marginLeft={'0'}
                                                />
                                            </Editable>
                                            {
                                            /*flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )*/
                                            }
                                        </Td>
                                    )
                                }
                            })}
                            {/*<Td>
                              <Button variant="deleteOutline" onClick={() => table.options.meta?.deleteRow(rowIndex)}>
                                    ❌
                              </Button>
                            </Td>*/}
                        </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            </TableContainer>
            <Flex w="full" mt={'50px'} justify={'flex-end'}>
                <Pagination 
                    isLoading={isLoading}
                    canPreviousPage={table.getCanPreviousPage()}
                    canNextPage={table.getCanNextPage()}
                    pageIndex={table.getState().pagination.pageIndex}
                    total={table.getPageCount()}
                    nextPage={table.nextPage}
                    previousPage={table.previousPage}
                    setPageIndex={table.setPageIndex}
                />
            </Flex>
        </>
    )
}


const checkIfInputShouldBeChecked = (rowValue) => {
    // check if row.name is not length 2
    // check if row.date_of_birth is weird, such as year is too old or if too young
    // check if consent is not I consent or yes
    const issues = []
    if (rowValue.name.split(" ").length != 2){
        issues.push('name')
    } else if (rowValue.name == ''){
        issues.push('name')
    }
    if (rowValue.date_of_birth){
        const dateOfBirth = new Date(rowValue.date_of_birth);
        const year = dateOfBirth.getFullYear();
        if (year < 1960 || year > 2022){
            issues.push('date_of_birth')
        }
    } else {
        issues.push('date_of_birth')
    }
    return issues;
}

const UserProfileFile = ({
    error, onSubmit, isSubmitting, onError, onChange, user
}) => {
    const { CSVReader } = useCSVReader();
    const { formatMessage } = useIntl();
    //const llmMutation = useGenerateLLM({response_type: 'csv_user_profile_headers', clinic: null, onSuccess: null, handleError: null});
    const [csvData, setCsvData] = useState([]);
    const [ isCSVLoading, setIsCSVLoading ] = useState(false);
    const REQUIRED_HEADERS = [
        'name', 'external_id', 'device_id', 'date_of_birth', 'gender', 'class_number'
    ]
    const EXTRA_HEADERS = []
    const DEFAULT_TABLE_HEADERS = [...REQUIRED_HEADERS, ...EXTRA_HEADERS]
    const OPTIONAL_HEADERS = [
        'guardian_name', 'class_level', 'consent'
    ]

    const PRE_HEADERS = [
        'name', 'date_of_birth', 'external_id', 'first_name', 'last_name', 'guardian_name', 'class_level', 'class_name', 'consent'
    ]
  
    const isCSVHeader = false;

    const clearData = () => {
        setCsvData([]);
    }

    const convertHeaderToSnakeCase = (header) => {
        // convert header to snake_case and remove periods
        // also remove other non alphanumeric characters
        return header.toLowerCase().replace(/ /g, '_').replace(/\./g, '').replace(/[^a-zA-Z0-9_]/g, '');
    }

    const isMissingHeaders = (headers) => {
        // check if header has all the REQUIRED_HEADERS
        // convert headers to snake_case
        const snakeCaseHeaders = headers.map((header) => {
            return convertHeaderToSnakeCase(header);
        });
        const missingHeaders = REQUIRED_HEADERS.filter((requiredHeader) => {
            return !snakeCaseHeaders.includes(requiredHeader)
        })
        if (missingHeaders.length > 0){
            const errorString = formatMessage({
                id: 'adminPage.form.importprofiles.uploadCSV.errorMissingHeaders',
                defaultMessage: 'Missing required headers: {headers}'
            }, {headers: missingHeaders.join(', ')})
            return errorString;
        }
        return null;
    }

    const createTableHeaders = (header) => {
        // from header, create object with keys: header, id, accessorKey
        // convert all id to snake_case
        // if snake_case id is in REQUIRED_HEADERS, then id as the key name,
        // if snake_case id is not in REQUIRED_HEADERS, then set id name as `extra_data__${key_name}`

        const newHeaders = []
        //newHeaders.push({
        //    header: 'No.', 
        //    id: 'index',
        //    accessorKey: 'index'
        //})
        //newHeaders.push({
        //    header: 'Fixed?',
        //    id: 'issues',
        //    accessorKey: 'issues'
        //})

        header.forEach((header, index) => {
            const snakeCaseHeader = convertHeaderToSnakeCase(header);
            // if header is a name, then split it into first_name, middle_name, and last_name
            if (snakeCaseHeader == 'name'){
                newHeaders.push({
                    header: 'First Name',
                    id: 'first_name',
                    accessorKey: 'first_name'
                })
                newHeaders.push({
                    header: 'Middle Name',
                    id: 'middle_name',
                    accessorKey: 'middle_name'
                })
                newHeaders.push({
                    header: 'Last Name',
                    id: 'last_name',
                    accessorKey: 'last_name'
                })
            }
            const headerId = DEFAULT_TABLE_HEADERS.includes(snakeCaseHeader) ? snakeCaseHeader : `extra_data__${snakeCaseHeader}`
            const newHeader = {
                header: header,
                id: headerId,
                accessorKey: headerId,
                index: index, // index of the header in the original header
            }
            newHeaders.push(newHeader)
        })
        // if external id is not in headers, then add it to the 2nd index

        if (!newHeaders.some((header) => header.id == 'external_id')){
            // add to second index
            newHeaders.splice(2, 0, {
                header: 'External ID',
                id: 'external_id',
                accessorKey: 'external_id'
            })
        }
        return newHeaders;
    }

    const handleOnUploadAccepted = (results) => {
        setIsCSVLoading(true);
        if (results.data && results.data.length > 1001) { // 1001 because the first row is the header
            onError(formatMessage({
            id: 'adminPage.form.importprofiles.uploadCSV.errorTooManyProfiles',
            defaultMessage: 'You can only upload up to 1000 profiles at a time.'
          }));
          setIsCSVLoading(false);
          return;
        }

        const newData = []
        const potentialIssuesData = []
        let tableRows = []
        // if header is incorrect, then return error. 
        onError(null);

        if (isCSVHeader == false){
            const missingHeaders = isMissingHeaders(results.data[0])
            if (missingHeaders != null){
                onError(missingHeaders)
                return;
            }
            const topHeader = createTableHeaders(results.data[0])
            // create object from converted header and values
            results.data.slice(1).forEach((row, index) => {
                // create an oject with topHeader as keys and row as values
                const newRow = {}
                topHeader.forEach((header) => {

                    // Check if it is a blank row
                    if (Object.values(row).every((value) => value?.trim() === '' || value === undefined)) {
                        return; // Skip this row
                    }

                    // if it is name, then split it into first_name and last_name
                    if (header.id == 'name'){
                        const trimmedName = row[header.index].trim().replace(/\s{2,}/g, ' ')
                        const name = trimmedName.split(' ')

                        newRow['name'] = trimmedName
                        newRow['first_name'] = name[0]
                        // for last_name, get the last element
                        if (name.length > 1){
                            newRow['last_name'] = name[name.length - 1]
                        } else {
                            newRow['last_name'] = ''
                        }
                        // for middle_name, get all elements except first and last
                        if (name.length > 2){
                            newRow['middle_name'] = name.slice(1, name.length - 1).join(' ')
                        } else {
                            newRow['middle_name'] = ''
                        }
                    } else if (header.id == 'gender'){
                        // capitalize gender
                        newRow[header.id] = (row[header.index] || "").trim().toUpperCase()
                    } else if (header.id == 'date_of_birth'){
                        // check if date_of_birth is a valid date
                        // format might be in mm/dd/yyyy or yyyy-mm-dd
                        const dateOfBirth = new Date(row[header.index]);
                        if (isNaN(dateOfBirth)){
                            newRow[header.id] = ''
                        } else {
                            newRow[header.id] = dateOfBirth.toISOString().split('T')[0]
                        }
                    } else {
                        newRow[header.id] = row[header.index]
                    }
                })
                console.log("new Row is", newRow)
                // check if it is a blank row
                if (Object.values(newRow).every((value) => value?.trim() == '' || value == undefined)){ // if all inputs are blank after stripping white spaces
                    return;
                }
                // check if there are any issues with the row
                // then add it to an issues field so the table can highlight it
                const weirdInputs = checkIfInputShouldBeChecked(newRow)
                if (weirdInputs.length > 0){
                    newRow['issues'] = weirdInputs
                    potentialIssuesData.push(newRow)
                } else {
                    newData.push(newRow)
                }
            })
            tableRows = [topHeader, ...potentialIssuesData, ...newData] // add potential issues to the top
        } else {
            tableRows = results.data
        }
        setCsvData(tableRows); // Parsed data as an array of arrays or objects
        setIsCSVLoading(false);
    };

    const handleApiSubmit = (data) => {
        // Here you could send `csvData` to your API
        //console.log("Ready to send data:", csvData);
        // Example: axios.post('/your-api-endpoint', csvData);
        onChange(data);
        onSubmit();
    };

    return (
      <Box py={'30px'}>
        <FormLabel align="center" pb={1}>
            <FormattedMessage 
                id={'adminPage.form.importprofiles.uploadCSV'}
                defaultMessage={'Upload CSV File'}
            />
        </FormLabel>
        <VStack align={'flex-start'} pb={'10px'}>
            <Text>
                <FormattedMessage
                    id={'adminPage.form.importprofiles.uploadCSV.descriptionRequired'}
                    defaultMessage={'Upload a CSV file with the following required headers'}  
                /><br />
                <FormattedMessage
                    id={'adminPage.form.importprofiles.uploadCSV.headersFormatted'}
                    defaultMessage={'{headers}'}  
                    values={{headers: REQUIRED_HEADERS.join(', ')}}
                />
            </Text>
            <Flex  direction={'column'}>
                <Text>
                    <FormattedMessage
                        id={'adminPage.form.importprofiles.uploadCSV.descriptionHeaders'}
                        defaultMessage={'Other headers should be in snake_case format (i.e. guardian_name, class_name, etc.)'}  
                    /><br />
                </Text>
            </Flex>
        </VStack>
        <Flex justify={'space-between'}>
            <CSVReader
                onUploadAccepted={handleOnUploadAccepted}
                config={{ header: isCSVHeader }}
            >
                {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
                <VStack>
                    <Flex justify={'space-between'}>
                        <div {...getRootProps()} 
                            style={{
                            border: '1px solid #ccc',
                            padding: '10px',
                            borderRadius: '15px',
                            cursor: user ? 'pointer' : 'not-allowed',
                            pointerEvents: user ? 'auto' : 'none',
                            opacity: user ? 1 : 0.5,
                            }}
                        >
                            {acceptedFile ? acceptedFile.name : formatMessage({id: "adminPage.form.importprofiles.button.addCSV", defaultMessage: "Click to select CSV"})}
                        </div>
                        {acceptedFile && (
                            <Button {...getRemoveFileProps()} style={{ margin: '10px' }}>
                                <FormattedMessage 
                                    id={'adminPage.form.importprofiles.removeFile'}
                                    defaultMessage={'Remove'}
                                />
                            </Button>
                        )}
                    </Flex>
                    <ProgressBar />
                    {error && <Text color={'red.500'}>{error}</Text>}
                </VStack>
                )}
            </CSVReader>
        </Flex>
  
        {/* Display parsed CSV data (optional) */}
        { csvData.length > 0 &&
          <Box mt={'30px'}>
            <PageTable 
                headers={csvData ? csvData[0] : []}
                data={csvData ? csvData.slice(1) : []}
                isLoading={isCSVLoading}
                isSubmitting={isSubmitting}
                handleApiSubmit={handleApiSubmit}
                clearData={clearData}
            />
          </Box>
        }
      </Box>
    );

}

export const UserProfileImport = ({
    onSuccess,
    onClose
}) => {
    const { formatMessage } = useIntl();
    const toast = useToast();
    const [ fileError, setFileError ] = useState(null);
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        console.log(values);
        const convertedValues = convertToAPIValues(values);
        // loop through my list of user_profiles and put all extra_data__ fields in a field extra_data
        for (let i = 0; i < convertedValues.user_profiles.length; i++){
            const profile = convertedValues.user_profiles[i];
            const extraData = {}
            for (const key in profile){
                if (key.startsWith('extra_data__')){
                    // remove the extra_data__ prefix
                    const newKey = key.replace('extra_data__', '');
                    extraData[newKey] = profile[key];
                    delete profile[key];
                }
            }
            profile.extra_data = extraData;
        }
        console.log(convertedValues);
        try{
            const resp = await createBulkUserProfiles(convertedValues);
            onSuccess && onSuccess(resp);
            toast({
                title: resp?.message ?  resp?.message : formatMessage({
                    id: 'adminPage.form.importprofiles.success', 
                    defaultMessage: 'Profiles have been successfully imported'
                }),
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top"
            });
            onClose();
            return null;
        } catch (err){
            console.log(err);
            setFileError(err.message);
        }

    }

    const handleFileError = (msg) => {
        setFileError(msg);
    }

    return (
        <Form
            onSubmit={handleSubmit}
            render = {
                ({
                    handleSubmit,
                    submitting,
                    values
                }) => {
                    return (
                        <Box as={'form'} onSubmit={handleSubmit}>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Text>
                                    <FormattedMessage
                                        id="adminPage.form.importprofiles.select_user.description"
                                        defaultMessage="Select a patient user to add user profiles to."
                                    />
                                </Text>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="user">
                                    {({input, meta}) => (
                                        <UserSearch   
                                            input={input}
                                            meta={meta}
                                            isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                            label={formatMessage({id: 'adminPage.form.field.user.label', defaultMessage: 'User'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.user.placeholder', defaultMessage: 'Search for a user'})}
                                            onSelect={(val) => {
                                                input.onChange(val);
                                            }}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <Box mt={rowSpacing}>
                                {fileError && <Text color={'red.500'}>{fileError}</Text>}
                            </Box>
                            <Field name="user_profiles">
                                {({input, meta}) => (
                                    <UserProfileFile 
                                        onSubmit={handleSubmit} 
                                        isSubmitting={submitting} 
                                        error={fileError} 
                                        onChange={input.onChange}
                                        onError={handleFileError} 
                                        user={values?.user}
                                    />
                                )}
                            </Field>
                        </Box>
                    )
                }
            }
        />
    )
};
